import React, { useEffect, useMemo } from 'react';

// helpers
import useFetch from '../../../../../../hooks/useFetch';
import { ICurrency } from '../../../../../../typings/accounting/currency';
import { currenciesAPI } from '../../../../../../api/accounting/currenciesAPI';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import {
  SelectOption,
  SelectProps,
} from '../../../../../DesignSystem/Core/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export interface IProps extends SelectProps<ICurrency> {
  autoSelectIfOneOption?: boolean;
  disableWhenOnlyOneOption?: boolean;
}

const CurrencySelect = ({
  autoSelectIfOneOption,
  disableWhenOnlyOneOption,
  ...rest
}: IProps) => {
  const { response, loading } = useFetch(
    () =>
      currenciesAPI.fetchCurrencies({
        page: 1,
        limit: AUTOCOMPLETE_RESULT_LIMIT,
        isExternal: false,
        isAccount: true,
        isActive: true,
      }),
    [],
  );

  const options = useMemo<SelectOption<ICurrency>[]>(() => {
    if (!response) {
      return [];
    }

    return response.data.map((e) => ({
      id: e.id,
      label: `${e.name} ${e.isoCode}`,
      model: e,
    }));
  }, [response]);

  useEffect(() => {
    if (autoSelectIfOneOption) {
      if (options.length === 1 && !rest.value) {
        rest.onChange && rest.onChange(options[0].id as any);
      }
    }
  }, [rest.value, autoSelectIfOneOption, options]);

  return (
    <FormSelect
      {...rest}
      loading={loading}
      options={options}
      disabled={
        rest.disabled || (disableWhenOnlyOneOption && options.length === 1)
      }
    />
  );
};

export default CurrencySelect;
