import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { ActionKeys } from '../../../../../components/Forms/TemplateForms/Onboarding/Components/LastStepSubmitButtons';
import { useDispatch } from 'react-redux';
import { onboardingAPI } from '../../../../../api/onboarding/onboardingAPI';
import { OnboardingEntryTypes } from 'enums/onboarding/crm';
import { onboardingAPIAdapter } from '../../../../../apiAdapters/onboarding/onboardingAPIAdapter';
import { updateActiveApplication } from 'redux/actions/applications';

// components
import LoadingWrapper from '../../../../../components/WrapperComponents/LoadingWrapper';
import ApplicationDocumentationForm, {
  FormValuesModel,
} from '../../../../../components/Forms/TemplateForms/Onboarding/ApplicationDocumentationForm';

interface IProps {
  isViewOnly: boolean;
  onAction: (key: ActionKeys | null) => void;
  onboardingData: {
    applicationId: string;
    contactId: string;
    clientGroupId: string;
  };
}

const ApplicationDocumentation = ({
  onAction,
  onboardingData,
  isViewOnly,
}: IProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'onboarding']);
  const [isSubmittedForReview, setIsSubmittedForReview] = useState<
    boolean | null
  >(null);
  const [updateAnswersTrigger, updateAnswers] = useState<any>();

  const {
    response: onboardingStatusResponse,
    loading: onboardingStatusLoading,
  } = useFetch(
    () =>
      onboardingAPI.fetchStatusForOnboardingItem(
        onboardingData.contactId,
        onboardingData.applicationId,
      ),
    [onboardingData],
  );

  const { response: answersResponse, loading: answersLoader } = useFetch(
    () =>
      onboardingAPI.fetchApplicationDocumentationStepAnswers(
        onboardingData.clientGroupId as string,
        onboardingData.contactId,
      ),
    [onboardingData, updateAnswersTrigger],
  );

  useEffect(() => {
    if (onboardingStatusResponse) {
      setIsSubmittedForReview(
        onboardingStatusResponse.informationFilled.applicationDocumentation,
      );
    }
  }, [onboardingStatusResponse]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!answersResponse || !onboardingStatusResponse) {
      return null;
    }

    return onboardingAPIAdapter.generateDataForApplicationDocumentationFromOnboardingAnswers(
      answersResponse,
      onboardingStatusResponse,
    );
  }, [answersResponse, onboardingStatusResponse]);

  const onSubmit = async (values: FormValuesModel) => {
    switch (values.submitActionType) {
      case 'save-back':
        {
          await submitApplicationDocumentationStep(values);
          message.success(t('success_save'));
          onAction('save-back');
        }
        break;

      case 'save':
        {
          await submitApplicationDocumentationStep(values);
          message.success(t('success_save'));
          updateAnswers({});
          setIsSubmittedForReview(true);
        }
        break;

      case 'submit':
        {
          await submitApplicationDocumentationStep(values).then(async () => {
            await onboardingAPI.submitApplicationForReview();
            await dispatch(updateActiveApplication());
            setIsSubmittedForReview(true);
          });

          onAction('submit');
        }
        break;
    }
  };

  async function submitApplicationDocumentationStep(values: FormValuesModel) {
    const formattedBody =
      onboardingAPIAdapter.submitApplicationDocumentationStep(
        values,
        initialFormValues as FormValuesModel,
        onboardingData.clientGroupId as string,
        onboardingData.contactId,
        OnboardingEntryTypes.Contact,
      );

    await onboardingAPI.submitApplicationDocumentationStep(formattedBody);
  }

  return (
    <LoadingWrapper loading={answersLoader || onboardingStatusLoading}>
      {initialFormValues && (
        <ApplicationDocumentationForm
          canSendMessage
          showBackButton
          canSubmitForReview={!!isSubmittedForReview}
          disabled={isViewOnly}
          onSubmit={onSubmit}
          initialFormValues={initialFormValues}
        />
      )}
    </LoadingWrapper>
  );
};

export default ApplicationDocumentation;
