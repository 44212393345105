import React, { useMemo } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../../../api/relationships/relationshipsAPI';
import { message } from 'antd';
import { IEntity } from '../../../../../../../typings/application/entity';
import { StateModel } from '../../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { IApplication } from 'typings/application/applications';
import { ContactModel } from '../../../../../../../typings/application/contact';
import { RelationshipHelpers } from '../../../../../../../helpers/crm/relationship';
import { AdditionalFieldHelpers } from '../../../../../../../helpers/additionalField';
import { relationshipsAPIAdapter } from '../../../../../../../apiAdapters/relationships/relationshipsAPIAdapter';
import { AdditionalFieldFormItemModel } from '../../../../../../../components/Forms/FormComponents/AdditionalField';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import {
  GraphEdge,
  GraphNode,
  NodeStatuses,
} from '../../../../../../../components/Charts/GraphChart';

// constants
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from '../../../../../../../enums/onboarding/crm';

// components
import DivAlignCenter from '../../../../../../../components/Additional/DivAlignCenter';
import WarningIconWithText from '../../../../../../../components/Additional/WarningIconWithText';
import EditNodeModalDialog, {
  FormValuesModel,
} from '../../../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/EditNodeModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  isViewOnly: boolean;
  data: {
    activeScopeId: string;
    parentNode: GraphNode;
    edges: GraphEdge[];
    clientGroupId: string;
    allNodes: GraphNode[];
  } | null;
}

const EditNodeModal = ({
  isVisible,
  closeCallback,
  isViewOnly,
  data,
}: IProps) => {
  const { t } = useTranslation('onboarding');
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );
  const activeApplication = useSelector<StateModel, IApplication | null>(
    (state) => state.applications.activeApplication,
  );

  const modalTitle = useMemo(() => {
    if (!data) {
      return '';
    }

    const title =
      data.parentNode.entryType === OnboardingEntryTypes.Contact
        ? t('node_modal.title.for_contact')
        : t('node_modal.title.for_organization');

    if (!isTwoFactorVerificationEnabled) {
      return (
        <DivAlignCenter>
          <StyledTitle>{title}</StyledTitle>
          <StyledWarningInfoNot2FA text={t('node_modal.warning_not_2fa')} />
        </DivAlignCenter>
      );
    }

    return title;
  }, [data, isTwoFactorVerificationEnabled]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!data || !isVisible) {
      return null;
    }

    const { parentNode, edges, clientGroupId, allNodes, activeScopeId } = data;
    const entity = parentNode.model as IEntity;
    const contact = parentNode.model as ContactModel;

    const hashNodes = allNodes.reduce<Record<string, GraphNode>>((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});

    const relationships = parentNode.isApplicantOrganization
      ? []
      : edges.map((e) => {
          const { relationship, relationshipTemplate } = e;

          const parentNode = hashNodes[e.target];
          const childNode = hashNodes[e.source];
          const isApplicantRelationship =
            (hashNodes[e.target].isApplicantContact ||
              hashNodes[e.target].isApplicantOrganization) &&
            (hashNodes[e.source].isApplicantContact ||
              hashNodes[e.source].isApplicantOrganization);

          const additionalFields: AdditionalFieldFormItemModel[] =
            AdditionalFieldHelpers.filterAndMapAdditionalFieldsFromTemplateToFormModel(
              relationshipTemplate,
              childNode.entryType,
              relationship,
            );

          return {
            customId: relationship._id,
            id: relationship._id,
            isApplicantRelationship,

            relationshipTemplate: {
              id: relationshipTemplate._id,
              initialOption: {
                id: relationshipTemplate._id,
                label: RelationshipHelpers.getRelationshipName(
                  {
                    parentName: relationshipTemplate.parentName,
                    childName: relationshipTemplate.childName,
                  },
                  RelationshipTrackTypes.Parent,
                ),
              },
              childRelationshipLabel: RelationshipHelpers.getRelationshipName(
                {
                  parentName: relationshipTemplate.parentName,
                  childName: relationshipTemplate.childName,
                },
                RelationshipTrackTypes.Child,
              ),
            },

            parent: {
              id: childNode.id,
              type: childNode.entryType,
            },

            child: {
              id: parentNode.id,
              type: parentNode.entryType,
              label: parentNode.label,

              autocompleteInitialValue: {
                id: parentNode.id,
                label: parentNode.label,
              },
            },

            isOwnership: e.isOwnership,
            disableAdditionalFields: e.isOwnership,

            additionalFields,
          };
        });

    // User should be able able to update node info only when the node is new (was not created before)
    return {
      activeScopeId,
      clientGroupId,
      disableAbilityToEditOwnershipRelationships: true,
      clientUserId: contact?.onlineAccess?.clientGroupUserId || null,
      entryType: parentNode.entryType,
      hideRelationshipsSection: parentNode.isApplicantOrganization,
      disableNodeForm:
        data?.parentNode.status !== NodeStatuses.New ||
        data?.parentNode.sharedData,

      parentForNewRelationships: {
        id: parentNode.id,
        type: parentNode.entryType,
        autocompleteInitialValue: {
          id: parentNode.id,
          content: parentNode.label,
          model: parentNode.model,
        },
      },

      contact:
        parentNode.entryType === OnboardingEntryTypes.Contact
          ? {
              isPermissionsSelectAvailable: true,
              id: contact._id,
              firstName: contact.firstName,
              middleName: contact.middleName,
              lastName: contact.lastName,
              isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
              pepInformation: contact.pepInformation,
              isOnlineUser: !!contact.onlineAccess,
              adminPermissionType: contact.onlineAccess
                ? contact.onlineAccess.adminPermissionType
                : null,
              accountPermissions: contact.onlineAccess
                ? contact.onlineAccess.permissionType
                : null,
              phone: contact.phoneNumbers.length
                ? contact.phoneNumbers.find((e) => e.isPrimary)?.number || ''
                : '',
              email: contact.emails.length
                ? contact.emails.find((e) => e.isPrimary)?.address || ''
                : '',
            }
          : null,

      organization:
        parentNode.entryType === OnboardingEntryTypes.Organization
          ? {
              id: entity._id,
              name: entity.names[0].name,
              isRegulated:
                typeof entity.isRegulated === 'boolean'
                  ? entity.isRegulated
                  : null,
              regulatedCountries: entity.regulationCountry,
            }
          : null,
      keyRelationshipTemplateId: parentNode.model
        .keyRelationshipTemplateId as string,
      clientGroupEntryType:
        activeApplication?.crmItemType as OnboardingEntryTypes,
      relationships,
    };
  }, [isVisible, data, activeApplication]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (initialFormValues) {
      const formattedRequestBody =
        relationshipsAPIAdapter.generateRequestBodyForEditNode(
          values,
          initialFormValues,
        );
      await relationshipsAPI.updateRelationships(formattedRequestBody);
      message.success(t('structure.relationships.edit_modal.success_edit'));
    }
  };

  return (
    <EditNodeModalDialog
      showSeeMoreInfoButton
      disabled={isViewOnly || !isTwoFactorVerificationEnabled}
      title={modalTitle}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

const StyledWarningInfoNot2FA = styled(WarningIconWithText)`
  margin-left: ${({ theme }) => theme.marginXs};
`;

const StyledTitle = styled.div`
  margin-right: ${({ theme }) => theme.marginXs};
`;

export default EditNodeModal;
