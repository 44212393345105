import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import { AccountsHelpers } from 'helpers/accounts';
import { FORMAT_AMOUNT_REG } from 'constants/regularExpressions';
import { StyledComponentProps } from '../../../../typings/common';

// components
import { InputNumber as AntDInputNumber } from 'antd';

export type InputSizes = 'large' | 'middle' | 'small';

export interface InputAmountProps extends StyledComponentProps {
  name?: string;
  size?: InputSizes;
  value?: number;
  disabled?: boolean;
  placeholder?: string;

  // Set precision to limit decimal places which can be entered
  precision?: number;

  onChange?: (value: string | number | null | undefined) => void;
}

const InputAmount = memo(
  ({
    value,
    onChange,
    disabled,
    placeholder,
    name,
    size,
    precision = 2,
    ...rest
  }: InputAmountProps) => {
    const { t } = useTranslation('form');
    const [tempValue, setTempValue] = useState<string | number | undefined>(
      value,
    );

    useEffect(() => {
      if (value !== tempValue) {
        setTempValue(value);
      }
    }, [value]);

    // Just save a new value, this values will be parsed in onBlur handler
    const handleOnChange = (newValue: string | number) => {
      setTempValue(newValue);
    };

    // Handle on blur event
    // - Handle the case when user entered something wierd like a string (e.g. 12.12.12.12)
    // - Round value to 2 decimal places if it's needed
    // - Save new values
    const handleOnBlur = (newValue: string | number | undefined = 0) => {
      let inputValue = newValue;

      if (!Number(inputValue)) {
        inputValue = parseFloat(String(newValue));
      }

      let roundedNumber = 0;

      if (Number(inputValue) > 0) {
        roundedNumber = AccountsHelpers.roundNumber(+inputValue, 2);
      }

      // Save new value
      setTempValue(roundedNumber);
      onChange && onChange(roundedNumber);
    };

    const inputNumberAmountFormatting = useMemo(
      () => ({
        formatter: (value: any) =>
          String(value).replace(FORMAT_AMOUNT_REG, ','),
        parser: (value: any) => {
          if (!value.length) {
            return;
          }

          // Remove formatter
          const formattedValue = value.replaceAll(',', '');

          // Regex for number value with dynamic limit for decimal places
          const reg = new RegExp(`^\\d+\\.?\\d{0,${precision}}$`);

          if (reg.test(formattedValue)) {
            return formattedValue;
          } else {
            return formattedValue.substring(0, formattedValue.length - 1);
          }
        },
      }),
      [precision],
    );

    return (
      <AntDInputNumber
        {...rest}
        {...inputNumberAmountFormatting}
        step={0.01}
        size={size}
        name={name}
        value={tempValue as any}
        onBlur={() => handleOnBlur(tempValue)}
        onChange={(newValue) => handleOnChange(newValue as number)}
        disabled={disabled}
        placeholder={placeholder || t('enter_value')}
      />
    );
  },
);

export default InputAmount;
