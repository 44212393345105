import React, { useCallback, useMemo, useState } from 'react';

// helpers
import { IApplication } from '../../../../../typings/application/applications';

// components
import EditNodeModal from './ModalDialogs/EditNodeModal';
import GraphChart, {
  GraphData,
  GraphEdge,
  GraphNode,
} from '../../../../../components/Charts/GraphChart';

interface IProps {
  graph: GraphData;
  isViewOnly: boolean;
  activeScopeId: string;
  activeApplication: IApplication;
  onModify: () => void;
}

enum NodeOptions {
  EditNode = 'edit_node',
}

const InnerGraph = ({
  graph,
  isViewOnly,
  activeApplication,
  onModify,
  activeScopeId,
}: IProps) => {
  const [openedModal, setOpenedModal] = useState<{
    modalKey: 'edit-node';
    edge?: GraphEdge;
    childNode?: GraphNode;
    parentNode?: GraphNode;
    edges?: GraphEdge[];
    clientUserId?: string;
    isOnlineContactWithLastRelationship?: boolean;
  } | null>(null);

  const onEditNodeClick = useCallback(
    (node: GraphNode) => {
      const nodeEdges = graph.edges.filter((e) => e.source === node.id);

      setOpenedModal({
        modalKey: 'edit-node',
        parentNode: node,
        edges: nodeEdges,
      });
    },
    [graph],
  );

  const handleModalClose = useCallback((wasModified?: boolean) => {
    if (wasModified) {
      onModify();
    }

    setOpenedModal(null);
  }, []);

  const handleEllipsisOptionClick = useCallback(
    (key: string, node: GraphNode) => {
      switch (key) {
        case NodeOptions.EditNode:
          onEditNodeClick(node);
          break;
      }
    },
    [],
  );

  const formattedGraphData = useMemo<GraphData>(() => {
    const result: GraphData = { nodes: [], edges: [] };

    result.nodes = graph.nodes.map((n) => {
      return {
        ...n,
        actions: {
          onClick: () => onEditNodeClick(n),
        },
      };
    });

    result.edges = [...graph.edges];

    return result;
  }, [graph, isViewOnly]);

  return (
    <>
      <GraphChart
        data={formattedGraphData}
        displaySharedDataStatusIcon
        onEllipsisOptionClick={handleEllipsisOptionClick}
      />

      <EditNodeModal
        isVisible={openedModal?.modalKey === 'edit-node'}
        isViewOnly={isViewOnly}
        closeCallback={handleModalClose}
        data={
          openedModal?.modalKey === 'edit-node'
            ? {
                activeScopeId,
                clientGroupId: activeApplication.clientGroupId as string,
                parentNode: openedModal?.parentNode as GraphNode,
                edges: openedModal.edges || [],
                allNodes: graph.nodes,
              }
            : null
        }
      />
    </>
  );
};

export default InnerGraph;
