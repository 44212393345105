import React, { useMemo } from 'react';

// helpers
import { FormValuesModel } from '..';
import { useFormikContext } from 'formik';

// components
import BulkCompleted from './BulkCompleted';
import ConfirmationStep from './ConfirmationStep';

const InnerForm = () => {
  const { values } = useFormikContext<FormValuesModel>();

  const content = useMemo(() => {
    switch (values.step) {
      case 'confirm-action':
        return <ConfirmationStep />;

      case 'completed':
        return <BulkCompleted />;
    }
  }, [values.step]);

  return <>{content}</>;
};

export default InnerForm;
