import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';

// components
import Text from '@core_components/Text';
import DivAlignCenter from '../DivAlignCenter';

interface IProps {
  noDataText?: string;
}

const NoDataContainer = ({ noDataText }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <StyledDivAlignCentert>
      <Text variant="h5">{noDataText || t('no_data')}</Text>
    </StyledDivAlignCentert>
  );
};

const StyledDivAlignCentert = styled(DivAlignCenter)`
  height: calc(100vh - 340px);
  justify-content: center;
`;

export default NoDataContainer;
