import APIConfig from '../config/api';
import { FetchResponseModel } from '../typings/common';
import { AxiosProgressEvent } from 'axios';
import { IDocumentBase, ITag } from '../typings/documents/documents';
import { DOCUMENT_UPLOAD_TIMEOUT } from '../constants/global';
import { APIService, AUTHORIZATION_HEADER } from './axiosInstance';

interface IFetchTagsResponse {
  tags: ITag[];
}

const documentsAPI = {
  fetchDocuments: (body: any) => {
    return APIService.get<FetchResponseModel<IDocumentBase>>(
      `${APIConfig.dmsApi}`,
      body,
    ).then((response) => response.data);
  },

  uploadDocument: (
    formData: any,
    uploadProgressCallback: (percentage: AxiosProgressEvent) => void,
    token?: string,
  ) => {
    const headers: Record<string, string> = {
      'Content-Type': 'multipart/form-data',
    };

    if (token) {
      headers[AUTHORIZATION_HEADER] = `Bearer ${token}`;
    }

    return APIService.post(`${APIConfig.dmsApi}/upload`, formData, {
      headers,
      addFingerprintToHeaders: true,
      timeout: DOCUMENT_UPLOAD_TIMEOUT,
      onUploadProgress: uploadProgressCallback,
    }).then((response) => response.data);
  },

  // TODO: delete API and all related components, since we are not using this at all
  fetchTags: (search: string) => {
    return APIService.get<IFetchTagsResponse>(
      `${APIConfig.dmsApi}/api/v1/tag/autocomplete`,
      { params: { search } },
    ).then((response) => response.data);
  },
};

export { documentsAPI };
