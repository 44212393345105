import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import {
  ICreateInternalTransferRequestBody,
  NewBlockchainTransferBodyModel,
  NewWireTransferBodyModel,
} from '../../apiAdapters/accounting/transfersAdapter';
import {
  INewInternalTransferBody,
  IEstimatedTransferRate,
} from '../../typings/accounting/transfer';

export interface ITransferDocument {
  dmsId: string;
  name: string;
  size: string;
  type: string;
  linkToDownload: string;
}

export type FetchTransactionBalancesPayload = {
  sequenceNumber: string;
  fromAccountNumber: string;
  currencyId: number;
  amount: number;
}[];

const transfersAPI = {
  createInternalTransfer: (newTransfer: ICreateInternalTransferRequestBody) => {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/transfers/internal`,
      newTransfer,
    ).then(({ data }) => data);
  },

  createWireTransfer: (newTransfer: NewWireTransferBodyModel) => {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/transfers/international`,
      newTransfer,
    ).then(({ data }) => data);
  },

  createBlockchainTransfer: (newTransfer: NewBlockchainTransferBodyModel) => {
    return APIService.post<number>(
      `${APIConfig.accountingApi}/transfers/blockchain`,
      newTransfer,
    ).then(({ data }) => data);
  },

  fetchEstimatedTransferInfo: (transferInfo: INewInternalTransferBody) => {
    return APIService.post<IEstimatedTransferRate>(
      `${APIConfig.accountingApi}/transfers/internal/estimate`,
      transferInfo,
    ).then(({ data }) => data);
  },

  fetchBalancesForWireTransfers: (
    transactions: FetchTransactionBalancesPayload,
  ) => {
    return APIService.post<{
      body: { availableBalance: number; sequenceNumber: string }[];
    }>(
      `${APIConfig.accountingApi}/transfers/international/batch/estimate`,
      transactions,
    ).then(({ data }) => data.body);
  },
};

export { transfersAPI };
