import APIConfig from '../../config/api';
import { APIService } from '../axiosInstance';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';
import { IKeyRelationshipTemplate } from '../../typings/application/key-relationship-templates';
import {
  IRelationshipTemplateModel,
  RelationshipTemplate,
} from '../../typings/application/relationship-template';
import {
  ArrayResponse,
  FetchParamsModel,
  FetchResponseModel,
} from '../../typings/common';

interface FetchRelationshipTemplatesParamsModel extends FetchParamsModel {
  search?: string;
  keyRelationshipTemplateId?: string;
}

interface FetchAvailableRelationshipTemplatesForRelationshipParamsModel {
  childId: string;
  parentType: OnboardingEntryTypes;
  relationshipScopeId: string;

  parentId?: string;
  parentNameSearch?: string;
}

const relationshipTemplatesAPI = {
  fetchRelationshipTemplates: (
    params: FetchRelationshipTemplatesParamsModel,
  ) => {
    return APIService.get<ArrayResponse<IRelationshipTemplateModel>>(
      `${APIConfig.crmApi}/relationship-templates`,
      { params },
    ).then(({ data }) => data);
  },

  fetchAvailableRelationshipTemplatesForRelationship: (
    params: FetchAvailableRelationshipTemplatesForRelationshipParamsModel,
  ) => {
    return APIService.get<FetchResponseModel<RelationshipTemplate>>(
      `${APIConfig.crmApi}/relationship-templates/available`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  fetchEntityTypeCategoryById: (id: string) => {
    return APIService.get<IKeyRelationshipTemplate>(
      `${APIConfig.crmApi}/key-relationship-templates/${id}`,
    ).then(({ data }) => data);
  },
};

export { relationshipTemplatesAPI };
