import React, { memo, useEffect, useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTimer from '../../../hooks/useTimer';
import useTranslation from '../../../hooks/useTranslation';
import { message, Typography } from 'antd';
import { StyledComponentProps } from 'typings/common';

// components
import Button from '../../DesignSystem/Core/Button';

interface IProps extends StyledComponentProps {
  timeout?: number;
  successMessage: string;
  shouldStartTimer?: boolean;
  startTimerTrigger?: any;
  startTimerFromDate?: Date;
  resendOtpCallback: () => Promise<void>;
  renderResendButton?: (
    resendOtpCallback: () => void,
    isSendingOtp: boolean,
  ) => React.ReactNode;
}

const ResendOtp = memo(
  ({
    timeout,
    successMessage,
    shouldStartTimer = true,
    startTimerFromDate,
    startTimerTrigger,
    resendOtpCallback,
    renderResendButton,
    ...rest
  }: IProps) => {
    const { t } = useTranslation('common');
    const { secondsLeft, startTimer } = useTimer(undefined, timeout);
    const [isSendingOTP, setSendingOTP] = useState(false);

    const resendOTP = async () => {
      setSendingOTP(true);
      try {
        startTimer();
        await resendOtpCallback();
        message.success(successMessage);
      } finally {
        setSendingOTP(false);
      }
    };

    const resendContent = useMemo(() => {
      if (secondsLeft > 0) {
        return (
          <StyledParagraph>
            {t('otp.expiration_description', { seconds: secondsLeft })}
          </StyledParagraph>
        );
      } else if (secondsLeft === 0) {
        return renderResendButton ? (
          renderResendButton(resendOTP, isSendingOTP)
        ) : (
          <StyledButton
            {...rest}
            type="bordered"
            size="large"
            onClick={resendOTP}
            loading={isSendingOTP}
          >
            {t('otp.resend_otp_button')}
          </StyledButton>
        );
      }
    }, [isSendingOTP, secondsLeft, renderResendButton]);

    useEffect(() => {
      if (shouldStartTimer) {
        startTimer(startTimerFromDate);
      }
    }, [startTimerTrigger, shouldStartTimer, startTimerFromDate]);

    return <>{resendContent}</>;
  },
);

const StyledButton = styled(Button)`
  margin-top: ${({ theme }) => theme.marginSm};
`;

const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
  margin-top: ${({ theme }) => theme.marginXs};
  margin-bottom: 0 !important;
`;

export default ResendOtp;
