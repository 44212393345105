import React from 'react';

// helpers
import { useField } from 'formik';
import { colorsTheme } from 'resources/theme/styled/colors';
import { FormValuesModel } from '../..';
import { AccountsHelpers } from 'helpers/accounts';

// components
import Text from '@core_components/Text';
import Spinner from '@core_components/Spinner';

interface IProps {
  fieldName: string;
}

const AvailableBalance = ({ fieldName }: IProps) => {
  const [field] =
    useField<FormValuesModel['transactions'][0]['balance']>(fieldName);

  return (
    <>
      {field.value && field.value.available !== null ? (
        <Text
          variant="body1"
          color={
            field.value.available < field.value.actual
              ? colorsTheme.colorWarning
              : undefined
          }
        >
          {`${AccountsHelpers.formatAmountToLocaleString(field.value.available)}`}
        </Text>
      ) : field.value?.available === null ? (
        <Spinner color={colorsTheme.colorLight} />
      ) : (
        ''
      )}
    </>
  );
};

export default AvailableBalance;
