import APIConfig from '../../config/api';
import { IEntity } from '../../typings/application/entity';
import { APIService } from '../axiosInstance';
import { ContactModel } from '../../typings/application/contact';
import { IClientGroupUser } from '../../typings/approvalManagement/accountManagement';
import { OnboardingStatusModel } from '../../typings/onboarding/onboarding';
import { FetchParamsModel, FetchResponseModel } from '../../typings/common';
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
} from '../../enums/onboarding/crm';

export interface FetchClientGroupMembersParams extends FetchParamsModel {
  type?: OnboardingEntryTypes;
  search?: string;
  isController?: boolean;
  has_online_access?: boolean;
  relationshipScopeId?: string;
  relationshipTemplateId?: string;
  relationshipTemplateMemberType?: RelationshipTrackTypes;
  isPossibleMemberOfInitialApprovalGroup?: boolean;
}

export interface ClientGroupMemberModel {
  id: string;
  type: OnboardingEntryTypes;
  contact: ContactModel;
  organization?: IEntity;
}

export interface NewUserFromExistingContactModel {
  contactId: string;
  onlineAccess: {
    adminPermissionType: AdministrationPermissionTypes;
    permission: AccountUserPermissionTypes;
  };
}

export interface ClientDetailsResponseModel {
  clientGroupUser: IClientGroupUser | null;
  item: ContactModel | IEntity;
  onboardingProcess: OnboardingStatusModel;
  metadata: {
    isOnboardingProcessCreatable: boolean;
    isOnboardingProcessRemovable: boolean;
  };
}

export interface UpdateClientPermissionsBodyModel {
  permissions: {
    adminPermissionType:
      | AdministrationPermissionTypes.Admin
      | AdministrationPermissionTypes.None
      | undefined;
    accountPermission: AccountUserPermissionTypes;
  };
}

const userManagementAPINew = {
  fetchClientGroupMembers: (params: FetchClientGroupMembersParams) => {
    return APIService.get<FetchResponseModel<ClientGroupMemberModel>>(
      `${APIConfig.crmApi}/client-groups/members`,
      {
        params,
      },
    ).then(({ data }) => data);
  },

  createNewUserFromExistingContact: (
    newUser: NewUserFromExistingContactModel,
  ) => {
    return APIService.post(
      `${APIConfig.crmApi}/client-groups/users`,
      newUser,
    ).then(({ data }) => data);
  },

  fetchClientDetails: (crmItemId: string, itemType: OnboardingEntryTypes) => {
    return APIService.get<ClientDetailsResponseModel>(
      `${APIConfig.crmApi}/client-groups/users/${crmItemId}/details`,
      {
        params: {
          itemType,
        },
      },
    ).then(({ data }) => data);
  },

  updateClientPermissions: (
    userId: string,
    body: UpdateClientPermissionsBodyModel,
  ) => {
    return APIService.put(
      `${APIConfig.crmApi}/client-groups/users/${userId}`,
      body,
    ).then(({ data }) => data);
  },
};

export { userManagementAPINew };
