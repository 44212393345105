import React, { useState, useEffect } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { darkTheme } from 'resources/theme/styled';
import { StateModel } from '../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { colorsTheme } from '../../../../resources/theme/styled/colors';
import { UploadStatus } from '../../../../constants/documents';
import { StateModel as UploadStateModel } from '../../../../redux/reducers/upload';

// components
import Text from '@core_components/Text';
import IconSVG from '../../../../components/DesignSystem/Core/IconSVG';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import { Col, Progress, Row } from 'antd';
import { ReactComponent as CheckIcon } from '../../../../resources/icons/remix-icons/check-line.svg';

const UploadDocumentNotification = () => {
  const { t } = useTranslation('documents');
  const { isFinished, status, progress, files } = useSelector<
    StateModel,
    UploadStateModel
  >((state) => state.upload);
  const [isNotificationVisible, setNotificationVisible] = useState(false);

  const uploadFilesCount = Object.keys(files).length;
  const label = t(uploadFilesCount === 1 ? 'file' : 'files');

  const isUploadActive = status === UploadStatus.ACTIVE;

  useEffect(() => {
    if (status === UploadStatus.ACTIVE) {
      setNotificationVisible(true);
    } else if (status === UploadStatus.INACTIVE || isFinished) {
      setTimeout(() => {
        setNotificationVisible(false);
      }, 1200);
    }
  }, [status, isFinished]);

  return !isNotificationVisible ? null : (
    <FixedBlock>
      <MainTextWrapper isUploadActive={isUploadActive}>
        {isUploadActive ? (
          <StyledRow wrap={false} justify="space-between">
            <Col>
              <Text variant="subtitle1">
                {t('uploading_progress', { count: uploadFilesCount, label })}
              </Text>
            </Col>
            <Col>
              <Text
                variant="subtitle1"
                weight="semi-bold"
              >{`${progress} %`}</Text>
            </Col>
          </StyledRow>
        ) : (
          <StyledCompletedContentWrapper>
            <Text variant="body1" weight="semi-bold">
              {t('uploaded_info')}
            </Text>
            <StyledIconSVG
              component={CheckIcon}
              color={colorsTheme.colorSuccess}
              size="middle"
            />
          </StyledCompletedContentWrapper>
        )}
      </MainTextWrapper>

      <ProgressWrapper isVisible={isUploadActive}>
        <Progress
          percent={progress}
          status="normal"
          showInfo={false}
          strokeColor={darkTheme.colorInfo}
          strokeWidth={7}
          strokeLinecap="square"
        />
      </ProgressWrapper>
    </FixedBlock>
  );
};

const FixedBlock = styled.div`
  position: fixed;
  right: 25px;
  bottom: 15px;

  width: 320px;
  min-width: 320px;
  max-height: 70px;
  min-height: 70px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: hidden;

  box-shadow: ${({ theme }) => theme.tableCardShadow};
  border-radius: ${({ theme }) => theme.borderRadiusRegular};
  background-color: ${({ theme }) => theme.colorDarkL3};
  animation: show 0.5s linear;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledRow = styled(Row)`
  padding: ${({ theme }) => theme.paddingSm};
  margin-top: ${({ theme }) => theme.marginSm};
`;

const MainTextWrapper = styled.div<{ isUploadActive: boolean }>`
  width: 100%;

  flex: 1;

  box-sizing: border-box;
  transition: all 0.7s ease-in;

  ${({ isUploadActive }) =>
    !isUploadActive &&
    `
      display: flex;
      align-items: center;
      justify-content: center;
  `}
`;

const StyledCompletedContentWrapper = styled(DivAlignCenter)`
  margin-bottom: -20px;
`;

const ProgressWrapper = styled.div<{ isVisible: boolean }>`
  transition: all 0.7s ease-in;
  width: 100%;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};

  .ant-progress-inner {
    margin-bottom: -8px !important;
  }
`;

const StyledIconSVG = styled(IconSVG)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default UploadDocumentNotification;
