import React, { useEffect, useState } from 'react';

// helpers
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';

// components
import AccountActivity from '../../modules/Accounts/AccountActivity';
import ExportAccountStatement, {
  AccountTransfersParamsModel,
} from 'modules/Accounts/AccountActivity/ExportAccountStatement';

interface LocationSearchParamsModel {
  page?: number;
  endDate?: number;
  startDate?: number;
  accountNumber?: string;
}

const AccountActivityPage = () => {
  const location = useLocation();
  const [accountTransfersParams, setAccountTransfersParams] =
    useState<AccountTransfersParamsModel | null>();
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();

  useEffect(() => {
    const {
      page,
      endDate,
      startDate,
      accountNumber,
    }: LocationSearchParamsModel = queryString.parse(location.search);

    if (accountNumber && page && endDate && startDate) {
      setAccountTransfersParams({
        page,
        endDate,
        startDate,
        accountNumber,
      });
    } else {
      setAccountTransfersParams(null);
    }
  }, [location.search]);

  useEffect(() => {
    setPageLayoutOptions((prev) => ({
      ...prev,
      extra: accountTransfersParams && (
        <ExportAccountStatement
          accountNumber={accountTransfersParams.accountNumber}
          accountTransfersParams={accountTransfersParams}
        />
      ),
    }));

    return () => clearContext();
  }, [accountTransfersParams, setPageLayoutOptions, clearContext]);

  return <AccountActivity />;
};

export default AccountActivityPage;
