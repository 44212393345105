import React, { useMemo, useState } from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../hooks/useTranslation';
import { ContactHelpers } from '../../../../helpers/crm/contact';
import { ENABLE_USER_BODY } from '../../../../constants/userManagement';
import { IClientGroupUser } from '../../../../typings/approvalManagement/accountManagement';
import { userManagementAPI } from '../../../../api/accountManagement/userManagementAPI';
import { ClientGroupUserStatuses } from 'enums/onboarding/crm';

// components
import Button from '../../../../components/DesignSystem/Core/Button';
import StatusLabel from '../../../../components/Typography/StatusLabel';
import SuccessButton from '../../../../components/Buttons/SuccessButton';
import { Col, message, Modal, Row } from 'antd';

interface IProps {
  visible: boolean;
  user: IClientGroupUser | null;
  closeCallback: (isEnabled: boolean) => void;
}

const EnableUserModal = ({ visible, user, closeCallback }: IProps) => {
  const { t } = useTranslation(['account_management', 'common']);
  const [isLoading, setLoading] = useState(false);
  const isEnable =
    user?.status.name !== ClientGroupUserStatuses.InvitationExpired;

  const username = useMemo(() => {
    return ContactHelpers.getFormattedContactName(
      user?.contact?.firstName || '',
      user?.contact?.lastName || '',
      user?.contact?.middleName || '',
    );
  }, [user]);

  const email = useMemo(() => {
    return user?.contact.emails.find(({ isPrimary }) => isPrimary)?.address;
  }, [user]);

  const modalContent = useMemo(() => {
    let result = null;

    if (isEnable) {
      result = (
        <StyledModalContent>
          {t('user_management.enable_user.description_part_1', {
            username,
            email,
          })}
          <br />
          <StatusLabel
            status="warning"
            content={t('user_management.enable_user.description_part_2')}
          />
        </StyledModalContent>
      );
    } else {
      result = (
        <StyledModalContent>
          {t('user_management.resend_invitation.description_part_1', {
            username,
            email,
          })}
          <br />
          <StatusLabel
            status="warning"
            content={t('user_management.resend_invitation.description_part_2')}
          />
        </StyledModalContent>
      );
    }

    return result;
  }, [isEnable, username, email, t]);

  const onCancel = () => {
    closeCallback(false);
  };

  const onEnable = async () => {
    if (user) {
      setLoading(true);
      try {
        await userManagementAPI.enableUser(user?.contact._id, ENABLE_USER_BODY);
        message.success(
          isEnable
            ? t('user_management.enable_user.success_submit_message', {
                username,
              })
            : t('user_management.resend_invitation.success_submit_message', {
                username,
              }),
        );
      } finally {
        setLoading(false);
      }
    }
    closeCallback(true);
  };

  return (
    <Modal
      width={500}
      closable={false}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Row justify="end" gutter={[16, 0]}>
          <Col>
            <Button onClick={onCancel} danger disabled={isLoading}>
              {t('cancel', { ns: 'common' })}
            </Button>
          </Col>
          <Col>
            <SuccessButton onClick={onEnable} loading={isLoading}>
              {isEnable
                ? t('user_management.enable_user.enable_user_button')
                : t(
                    'user_management.resend_invitation.resend_invitation_button',
                  )}
            </SuccessButton>
          </Col>
        </Row>
      }
    >
      {modalContent}
    </Modal>
  );
};

const StyledModalContent = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSm};
`;

export default EnableUserModal;
