import React from 'react';

// helpers
import useFetch from '../../../../../../../../../hooks/useFetch';
import { StateModel } from '../../../../../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { onboardingAPI } from '../../../../../../../../../api/onboarding/onboardingAPI';
import { ApprovalEntryNewModel } from '../../../../../../../../../typings/approvalManagement/accountManagement';
import { StateModel as ApplicationsStateModel } from '../../../../../../../../../redux/reducers/applications';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
} from 'enums/onboarding/crm';

// components
import InnerTree from './InnerTree';
import LoadingWrapper from '../../../../../../../../../components/WrapperComponents/LoadingWrapper';
import UserChangesSection from './UserChangesSection';
import NodesChangesSection from './NodesChangesSection';
import { Divider } from 'antd';

export interface ClientGroupTemplateDataModel {
  clientGroupId: string;
  relationshipScopeId: string;
  approvedRelationshipScopeId: string;
  clientGroupUserChanges: ClientGroupUserChangeFromQuery[];
}

export type ClientGroupUserChangeFromQuery = {
  clientGroupUserId: string;
  clientGroupUserName: string;
  newPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
  oldPermissions: {
    adminPermissionType: AdministrationPermissionTypes;
    type: AccountUserPermissionTypes;
  } | null;
};

interface IProps {
  approvalEntry: ApprovalEntryNewModel<ClientGroupTemplateDataModel>;
}

const ClientGroup = ({ approvalEntry }: IProps) => {
  const { activeApplication } = useSelector<StateModel, ApplicationsStateModel>(
    (state) => state.applications,
  );
  const { response, loading } = useFetch(
    () =>
      activeApplication && approvalEntry.changes.new
        ? onboardingAPI.fetchStructureStepRelationships(
            activeApplication.crmItemId,
            approvalEntry.changes.new.relationshipScopeId,
            activeApplication.crmItemId,
            activeApplication.clientGroup?.directClientId as string,
            approvalEntry.changes.new.approvedRelationshipScopeId,
          )
        : null,
    [approvalEntry, activeApplication],
  );

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <>
          <UserChangesSection
            approvalEntry={
              approvalEntry.changes.new as ClientGroupTemplateDataModel
            }
          />
          <Divider />
          <InnerTree graph={response} />
          <Divider />
          <NodesChangesSection graph={response} />
        </>
      )}
    </LoadingWrapper>
  );
};

export default ClientGroup;
