import React from 'react';

// helpers
import { useField } from 'formik';
import { FormValuesModel } from '../..';

interface IProps {
  fieldName: string;
}

const TransactionCurrency = ({ fieldName }: IProps) => {
  const [field] =
    useField<FormValuesModel['transactions'][0]['currency']>(fieldName);

  return <>{field.value}</>;
};

export default TransactionCurrency;
