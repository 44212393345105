import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// constants
import { TWO_COL_GRID_SIZES } from '../../../../../../../constants/grids';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import SectionIntro from '@common_components/Texts/SectionIntro';
import DeleteButton from '../../../../../../DesignSystem/Common/Buttons/DeleteButton';
import CurrencySelect from '../../../../../FormComponents/SelectInputs/Accounting/CurrencySelect';
import WireRangesSelect from '../../../../../FormComponents/SelectInputs/Accounting/WireRangesSelect';
import AccountTypeSelect from '../../../../../FormComponents/SelectInputs/Accounting/AccountTypeSelect';
import FormInputTextArea from '@common_components/Form/FormInputTextArea';
import HideIfDisabledForm from '../../../../../HideIfDisabledForm';
import InitialDepositRangeSelect from '../../../../../FormComponents/SelectInputs/Accounting/InitialDepositRangeSelect';
import EstimatedAmountFundsRangeSelect from '../../../../../FormComponents/SelectInputs/Accounting/EstimatedAmountFundsRangeSelect';
import CountrySelect, {
  IProps as CountrySelectProps,
} from '../../../../../FormComponents/SelectInputs/CRM/CountrySelect';
import { Card, Col, Row } from 'antd';

interface IProps {
  index: number;
  onRemoveClick: () => void;
}

const BankAccountForm = ({ index, onRemoveClick }: IProps) => {
  const { t } = useTranslation('onboarding');
  const { values } = useFormikContext<FormValuesModel>();
  const canRemove = values.accounts.length > 1;

  return (
    <StyledCard
      title={t('account_information.account_with_number', {
        number: index + 1,
      })}
      extra={
        canRemove && (
          <HideIfDisabledForm hideIfSubmitting>
            <DeleteButton onClick={onRemoveClick} />
          </HideIfDisabledForm>
        )
      }
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <FormField
            label={t('account_information.form_fields.purpose_of_account')}
            name={`accounts.${index}.purpose`}
            component={FormInputTextArea}
            additionalProps={{ autoSize: { minRows: 2, maxRows: 6 } }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            disabled
            label={t('account_information.form_fields.account_type')}
            name={`accounts.${index}.type`}
            component={AccountTypeSelect}
          />
        </Col>

        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t('account_information.form_fields.currency')}
            name={`accounts.${index}.currencyId`}
            component={CurrencySelect}
            additionalProps={{
              disableWhenOnlyOneOption: true,
              autoSelectIfOneOption: true,
            }}
          />
        </Col>
      </Row>

      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField
            label={t(
              'account_information.form_fields.expected_initial_deposit',
            )}
            name={`accounts.${index}.initialDeposit`}
            component={InitialDepositRangeSelect}
          />
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<CountrySelectProps>
            label={t(
              'account_information.form_fields.source_of_initial_deposit',
            )}
            name={`accounts.${index}.initialDepositOrigin`}
            component={CountrySelect}
          />
        </Col>
      </Row>

      <SectionIntro
        title={t('account_information.estimated_monthly_account_activity')}
        titleVariant="h4"
      />
      <Row gutter={[16, 0]}>
        <Col {...TWO_COL_GRID_SIZES}>
          <Text variant="body1">
            {t('account_information.form_fields.jurisdictions')}
          </Text>
        </Col>
        <Col {...TWO_COL_GRID_SIZES}>
          <FormField<CountrySelectProps>
            name={`accounts.${index}.jurisdictions`}
            component={CountrySelect}
            additionalProps={{ mode: 'multiple' }}
          />
        </Col>
      </Row>

      <StyledRow gutter={[16, 0]}>
        <Col offset={4} span={10}>
          <Text variant="body1">{t('account_information.volume')}</Text>
        </Col>

        <Col span={10}>
          <Text variant="body1">{t('account_information.amount')}</Text>
        </Col>
      </StyledRow>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('account_information.incoming_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`accounts.${index}.incomingWiresNumber`}
            component={WireRangesSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`accounts.${index}.estimatedIncomingFunds`}
            component={EstimatedAmountFundsRangeSelect}
          />
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col xl={4} lg={4} md={24} sm={24} xs={24}>
          <Text variant="body1" weight="semi-bold">
            {t('account_information.outgoing_activity')}
          </Text>
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`accounts.${index}.outgoingWiresNumber`}
            component={WireRangesSelect}
          />
        </Col>
        <Col xl={10} lg={10} md={24} sm={24} xs={24}>
          <FormField
            name={`accounts.${index}.estimatedOutgoingFunds`}
            component={EstimatedAmountFundsRangeSelect}
          />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.marginMd};

  .ant-card-head {
    border-bottom: 1px solid ${({ theme }) => theme.cardBorderColor};
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({ theme }) => theme.marginXs};
`;

export default BankAccountForm;
