import React, { useMemo, useRef, useState } from 'react';

// helpers
import moment from 'moment';
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { FormikProps } from 'formik';
import { generateUniqId } from 'helpers/utils';
import { AccountsHelpers } from 'helpers/accounts';
import { submitMultipleWireTransfersValidationSchema } from 'validations/accounting/transfers';
import { FormValuesModel as SubmitBulkFormValuesModal } from 'components/ModalDialogs/TemplateModalDialogs/Transfers/SubmitTransactionsBulkModal';

// components
import Form from '@core_components/Form';
import SubmitButton from 'components/Buttons/SubmitButton';
import SubmitTransactionsModal from './SubmitTransactionsModal';
import HideIfDisabledForm from 'components/Forms/HideIfDisabledForm';
import { Col, Row } from 'antd';
import WireTransferGridForm, {
  FormValuesModel,
} from 'components/Forms/TemplateForms/Transfers/WireTransferGridForm';
import {
  FetchTransactionBalancesPayload,
  transfersAPI,
} from 'api/accounting/transfersAPI';

const SubmitButtonGridSizes = { xl: 4, lg: 6, md: 8, sm: 24, xs: 24 };

const SubmitMultipleTransactionsForm = () => {
  const formRef = useRef<FormikProps<FormValuesModel>>(null);
  const { t } = useTranslation('common');
  const [selectedTransactionsForModal, setSelectedTransactionsForModal] =
    useState<SubmitBulkFormValuesModal['transactions'] | null>(null);

  const initialValues = useMemo<FormValuesModel>(() => {
    return {
      transactions: [
        {
          customId: generateUniqId(),
          from: null,
          balance: null,
          purpose: '',
          amount: null,
          currencyId: null,
          currency: null,
          valueDate: moment(),
          fromReference: '',
          to: null,
          toFieldLabel: null,
          toReference: '',
        },
      ],
    };
  }, []);

  const handleSubmit = (values: FormValuesModel) => {
    setSelectedTransactionsForModal(values.transactions);
  };

  const handleModalClose = async (
    form: FormikProps<FormValuesModel>,
    wasSubmitted?: boolean,
    values?: SubmitBulkFormValuesModal,
  ) => {
    setSelectedTransactionsForModal(null);

    if (wasSubmitted && values) {
      if (
        values &&
        values.result?.failedTransactions &&
        values.result.failedTransactions.length
      ) {
        const failedTransactions = form.values.transactions.filter(
          (_, i) =>
            values.result &&
            values.result.failedTransactions.some(
              (f) => f.transactionIndex === i,
            ),
        );

        const formattedTransactions: FetchTransactionBalancesPayload =
          failedTransactions.map((e) => ({
            sequenceNumber: e.customId,
            fromAccountNumber: e.from as string,
            currencyId: e.currencyId as number,
            amount: e.amount
              ? AccountsHelpers.convertAmountFromIntToBigInt(e.amount)
              : 0,
          }));

        const response = await transfersAPI.fetchBalancesForWireTransfers(
          formattedTransactions,
        );

        const updatedTransactions = failedTransactions.map((transaction) => {
          const updatedTransaction = response.find(
            (responseTransaction) =>
              responseTransaction.sequenceNumber === transaction.customId,
          );

          if (updatedTransaction) {
            return {
              ...transaction,
              balance: transaction.balance
                ? {
                    ...transaction.balance,
                    available: AccountsHelpers.convertAmountFromBigIntToInt(
                      updatedTransaction.availableBalance,
                    ),
                  }
                : null,
            };
          }

          return transaction;
        });

        form.resetForm({
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          status: undefined,
          submitCount: 0,
          values: {
            transactions: updatedTransactions,
          },
        });
      } else {
        form.resetForm({
          errors: {},
          touched: {},
          isSubmitting: false,
          isValidating: false,
          status: undefined,
          submitCount: 0,
          values: initialValues,
        });
      }
    }
  };

  return (
    <>
      <Form<FormValuesModel>
        innerRef={formRef}
        confirmExitWithoutSaving
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={submitMultipleWireTransfersValidationSchema}
        renderForm={(form) => (
          <>
            <WireTransferGridForm />
            <HideIfDisabledForm>
              <Row justify="end">
                <Col {...SubmitButtonGridSizes}>
                  <StyledSubmitButton type="primary">
                    {t('submit', { ns: 'common' })}
                  </StyledSubmitButton>
                </Col>
              </Row>
            </HideIfDisabledForm>
            <SubmitTransactionsModal
              transactions={selectedTransactionsForModal}
              isVisible={!!selectedTransactionsForModal}
              closeCallback={(wasSubmitted, values) =>
                handleModalClose(form, wasSubmitted, values)
              }
            />
          </>
        )}
      />
    </>
  );
};

const StyledSubmitButton = styled(SubmitButton)`
  width: 100%;
  padding: 0px;
  min-width: 0px;
`;

export default SubmitMultipleTransactionsForm;
