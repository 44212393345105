import * as yup from 'yup';
import {
  ContactAddressTypes,
  OrganizationAddressTypes,
} from '../../enums/onboarding/crm';
import {
  NULLABLE_BOOLEAN_VALIDATION_RULE,
  CRM_NAME_VALIDATION_RULE,
  STRING_VALIDATION_RULE,
} from '../common';

const addressValidator = yup.object().shape({
  type: yup.array().nullable().of(STRING_VALIDATION_RULE),
  street: CRM_NAME_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.street',
      number: 50,
    },
  }),
  city: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.city',
      number: 20,
    },
  }),
  country: STRING_VALIDATION_RULE.nullable().max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.country',
      number: 50,
    },
  }),
  state: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.state_region',
      number: 20,
    },
  }),
  postalCode: CRM_NAME_VALIDATION_RULE.max(10, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.postal_code',
      number: 10,
    },
  }),
  documents: yup.array().when('type', {
    is: (val: string[]) => val?.includes(ContactAddressTypes.PermanentAddress),
    then: (schema) =>
      schema
        .min(1, 'form:validation_messages.required')
        .required('form:validation_messages.required'),
  }),
});

const addressValidatorRequired = yup.object().shape({
  type: yup
    .array()
    .nullable()
    .of(STRING_VALIDATION_RULE)
    .min(1, {
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:identification.form_fields.addresses.address_type',
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:identification.form_fields.addresses.address_type',
      },
    }),
  street: CRM_NAME_VALIDATION_RULE.max(50, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.street',
      number: 50,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'onboarding:identification.form_fields.addresses.street' },
  }),
  city: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.city',
      number: 20,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: { label: 'onboarding:identification.form_fields.addresses.city' },
  }),
  country: STRING_VALIDATION_RULE.nullable()
    .max(50, {
      key: 'form:validation_messages.max_characters_with_label',
      values: {
        label: 'onboarding:identification.form_fields.addresses.country',
        number: 50,
      },
    })
    .required({
      key: 'form:validation_messages.required_with_label',
      values: {
        label: 'onboarding:identification.form_fields.addresses.country',
      },
    }),
  state: CRM_NAME_VALIDATION_RULE.max(20, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.state_region',
      number: 20,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.state_region',
    },
  }),
  postalCode: CRM_NAME_VALIDATION_RULE.max(10, {
    key: 'form:validation_messages.max_characters_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.postal_code',
      number: 10,
    },
  }).required({
    key: 'form:validation_messages.required_with_label',
    values: {
      label: 'onboarding:identification.form_fields.addresses.postal_code',
    },
  }),
  documents: yup.array().when('type', {
    is: (val: string[]) => val?.includes(ContactAddressTypes.PermanentAddress),
    then: (schema) =>
      schema
        .min(1, 'form:validation_messages.required')
        .required('form:validation_messages.required'),
  }),
});

export const IdentificationStepForOrganizationShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.organization.identification_questions.is_regulated_title',
          },
        }),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema.min(1, {
                key: 'form:validation_messages.required_with_label',
                values: {
                  label:
                    'onboarding:identification.form_fields.organization.identification_questions.regulated_in_countries_title',
                },
              }),
          }),
      }),
  });

export const IdentificationStepForOrganizationValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.organization.identification_questions.is_regulated_title',
          },
        }),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema
                .min(1, {
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.organization.identification_questions.regulated_in_countries_title',
                  },
                })
                .required({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.organization.identification_questions.regulated_in_countries_title',
                  },
                }),
          }),
      }),

    canIssueBearerShares: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.organization.identification_questions.can_issue_bearer_shares_title',
          },
        }),
    }),

    addresses: yup.array().when('isSave', {
      is: true,
      then: (schema) => schema.of(addressValidator),
      otherwise: (schema) =>
        schema.of(addressValidatorRequired).test(
          'addresses',
          {
            key: 'form:validation_messages.provide_all_types_with_label',
            values: { label: 'common:addresses' },
          },
          (value) => {
            // Need to test if all address types were selected
            if (!value || !value.length) {
              return false;
            }

            let selectedTypes = '';

            // Generate a string with all selected types from each address section, e.g. 'business_address,registered_office,'
            value.forEach((e) => {
              selectedTypes += `${e?.type},`;
            });

            let isValid = true;

            // Loop through required address types
            // If we are missing at least 1 address from REQUIRED_ADDRESS_TYPES then we can return false (validation is not passed)
            for (const el of Object.values(OrganizationAddressTypes)) {
              if (!selectedTypes.includes(el)) {
                isValid = false;
                break;
              }
            }

            return isValid;
          },
        ),
    }),
  });

export const IdentificationStepForIndividualShortValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label: 'onboarding:identification.form_fields.individual.pep_title',
          },
        }),
    }),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.individual.pep_information_title',
          },
        }),
    }),
  });

export const IdentificationStepForIndividualValidationSchema = yup
  .object()
  .shape({
    submitActionType: yup.mixed().nullable(),
    isSave: yup.boolean().nullable().defined(),

    isPEP: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label: 'onboarding:identification.form_fields.individual.pep_title',
          },
        }),
    }),

    pepInformation: STRING_VALIDATION_RULE.when('isPEP', {
      is: true,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.individual.pep_information_title',
          },
        }),
    }),

    isRegulated: NULLABLE_BOOLEAN_VALIDATION_RULE.when('isSave', {
      is: false,
      then: (schema) =>
        schema.required({
          key: 'form:validation_messages.required_with_label',
          values: {
            label:
              'onboarding:identification.form_fields.organization.identification_questions.is_regulated_title',
          },
        }),
    }),

    nationality: yup.array().when('isSave', {
      is: false,
      then: (schema) =>
        schema
          .of(STRING_VALIDATION_RULE)
          .required('form:validation_messages.required')
          .min(1, 'form:validation_messages.required'),
    }),

    regulationCountry: yup
      .array()
      .of(STRING_VALIDATION_RULE)
      .when('isRegulated', {
        is: true,
        then: (schema) =>
          schema.when('isSave', {
            is: false,
            then: (schema) =>
              schema.min(1, {
                key: 'form:validation_messages.required_with_label',
                values: {
                  label:
                    'onboarding:identification.form_fields.organization.identification_questions.regulated_in_countries_title',
                },
              }),
          }),
      }),

    addresses: yup.array().when('isSave', {
      is: true,
      then: (schema) => schema.of(addressValidator),
      otherwise: (schema) =>
        schema.of(addressValidatorRequired).test(
          'addresses',
          {
            key: 'form:validation_messages.provide_all_types_of_addresses_contact',
            values: { label: 'common:addresses' },
          },
          (value) => {
            // Need to test if all address types were selected
            if (!value || !value.length) {
              return false;
            }

            let selectedTypes = '';

            // Generate a string with all selected types from each address section, e.g. 'business_address,registered_office,'
            value.forEach((e) => {
              selectedTypes += `${e?.type},`;
            });

            let isValid = true;

            // Loop through required address types
            // If we are missing Mailing or Permanent addresses from REQUIRED_ADDRESS_TYPES then we can return false (validation is not passed)
            if (
              !selectedTypes.includes(ContactAddressTypes.MailingAddress) ||
              !selectedTypes.includes(ContactAddressTypes.PermanentAddress)
            ) {
              isValid = false;
            }

            return isValid;
          },
        ),
    }),

    passports: yup
      .array()
      .of(
        yup.object().shape({
          number: STRING_VALIDATION_RULE.min(2, {
            key: 'form:validation_messages.min_characters_with_label',
            values: {
              label:
                'onboarding:identification.form_fields.individual.passports.passport_number',
              number: 2,
            },
          }).max(100, {
            key: 'form:validation_messages.max_characters_with_label',
            values: {
              label:
                'onboarding:identification.form_fields.individual.passports.passport_number',
              number: 100,
            },
          }),
          country: STRING_VALIDATION_RULE,
          issuedAt: yup.date().nullable(),
          expirationDate: yup
            .date()
            .nullable()
            .min(
              new Date(),
              'form:validation_messages.date_should_be_greater_than_today',
            ),
          document: yup.array(),
        }),
      )
      .when('isSave', {
        is: true,
        otherwise: () =>
          yup.array().of(
            yup.object().shape({
              number: STRING_VALIDATION_RULE.min(2, {
                key: 'form:validation_messages.min_characters_with_label',
                values: {
                  label:
                    'onboarding:identification.form_fields.individual.passports.passport_number',
                  number: 2,
                },
              })
                .max(100, {
                  key: 'form:validation_messages.max_characters_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.passport_number',
                    number: 100,
                  },
                })
                .required({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.passport_number',
                  },
                }),
              country: STRING_VALIDATION_RULE.required({
                key: 'form:validation_messages.required_with_label',
                values: {
                  label:
                    'onboarding:identification.form_fields.individual.passports.country_of_passport',
                },
              }),
              issuedAt: yup
                .date()
                .nullable()
                .typeError({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.issue_date',
                  },
                })
                .required({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.issue_date',
                  },
                }),
              expirationDate: yup
                .date()
                .nullable()
                .typeError({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.expire_date',
                  },
                })
                .min(
                  new Date(),
                  'form:validation_messages.date_should_be_greater_than_today',
                )
                .required({
                  key: 'form:validation_messages.required_with_label',
                  values: {
                    label:
                      'onboarding:identification.form_fields.individual.passports.expire_date',
                  },
                }),
              document: yup
                .array()
                .min(1, 'form:validation_messages.required')
                .required('form:validation_messages.required'),
            }),
          ),
      }),
  });
