import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  memo,
  useContext,
} from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FormContext } from '@core_components/Form';
import { FormFieldHelpers } from 'helpers/form/formField';
import { DISABLED_FORM_STATUS } from 'constants/form';
import { useField, useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import InfoTooltip from 'components/Tooltips/InfoTooltip';
import { Col, Form, Row } from 'antd';

const FormItem = Form.Item;

enum VALIDATE_STATUS_ENUM {
  ERROR = 'error',
  SUCCESS = 'success',
}

enum LABEL_ALIGN_ENUM {
  LEFT = 'left',
  RIGHT = 'right',
}
export interface FormFieldPropsModel<ComponentProps = unknown> {
  // name of the field
  name: string;

  // field component (Input, Select etc.)
  component: React.ReactNode;

  // label text or label component for the field
  label?: string | React.ReactNode;
  labelTooltip?: string;

  // set this if you want to change the size of label grid col
  labelCol?: any;

  // change align position
  labelAlign?: LABEL_ALIGN_ENUM;

  // size of the field (by default it's large)
  size?: SizeType;

  disabled?: boolean;

  placeholder?: string;

  // show error message status (set false if you want to hide an error message, and show only red border)
  shouldShowErrorMessage?: boolean;

  onChange?: (e: any) => void;

  additionalProps?: ComponentProps;
}

export interface InnerComponentModel {
  name: string;
  size?: SizeType;
  onBlur: any;
  onChange: any;
  placeholder?: string;
  disabled?: boolean;
  value: any;
  checked?: boolean;
  validationRules: FieldValidationRule[];
}

interface InnerFormItemProps {
  component: any;
  labelAlign?: LABEL_ALIGN_ENUM;
  validateStatus: VALIDATE_STATUS_ENUM;
  innerComponentProps: InnerComponentModel;
  labelCol: any;

  label?: string | React.ReactNode;
  style?: any;
  help?: string;
}

const DEFAULT_LABEL_CONFIG = { span: 24 };
const DEFAULT_INPUT_WRAPPER_STYLE = { marginBottom: 0 };

export type FieldValidationRule = {
  type: 'required' | 'min' | 'max';
  params?: {
    min?: number;
    max?: number;
  };
};

// Custom Form Field component (using Formik)
// This component is responsible for handling form fields
function FormField<ComponentProps = unknown>({
  name,
  label = '',
  labelTooltip,
  labelCol,
  labelAlign = LABEL_ALIGN_ENUM.RIGHT,
  size = 'large',
  component: Component,
  disabled,
  shouldShowErrorMessage = true,
  placeholder,
  onChange,
  additionalProps,
}: FormFieldPropsModel<ComponentProps>) {
  const customFormContext = useContext(FormContext);

  const { t } = useTranslation('form');
  const { status, values, isSubmitting } = useFormikContext<any>();

  const [field, meta, helpers] = useField(name);
  const [privateValue, setPrivateValue] = useState<any>(
    field.value || undefined,
  );

  const fieldValidationRules = useMemo<FieldValidationRule[]>(() => {
    if (!values || !customFormContext.validationSchema || !name) {
      return [];
    }

    const result: FieldValidationRule[] = [];

    if (
      FormFieldHelpers.isRequired(
        customFormContext.validationSchema,
        name,
        values,
      )
    ) {
      result.push({ type: 'required' });
    }

    const minMaxRules = FormFieldHelpers.getMinMaxRules(
      customFormContext.validationSchema,
      name,
      values,
    );
    if (minMaxRules) {
      if (minMaxRules.min) {
        result.push({ type: 'min', params: { min: minMaxRules.min.value } });
      }

      if (minMaxRules.max) {
        result.push({ type: 'max', params: { max: minMaxRules.max.value } });
      }
    }

    return result;
  }, [name, customFormContext.validationSchema, values]);

  useEffect(() => {
    if (field.value !== privateValue) {
      setPrivateValue(field.value);
    }
  }, [field.value]);

  // handle input change event
  const handleChange = (e: any) => {
    let newValue: any = '';

    if (e) {
      newValue = e.target
        ? e.target.type === 'checkbox'
          ? e.target.checked
          : e.target.value
        : e;
    } else {
      newValue = typeof e === 'boolean' ? e : '';
    }

    setPrivateValue(newValue);
    helpers.setValue(newValue);
    onChange && onChange(e);
  };

  const handleBlur = useCallback(() => {
    return helpers.setTouched(true);
  }, []);

  const itemLabel = useMemo(() => {
    if (!label) {
      return null;
    }

    if (labelTooltip) {
      return (
        <Row wrap={false} align="middle">
          <Col>
            <Text variant="label1" weight="regular">
              {label}
            </Text>
          </Col>
          <Col>
            <InfoTooltip placement="top" tooltipContent={labelTooltip} />
          </Col>
        </Row>
      );
    }

    return (
      <StyledText variant="label1" weight="regular">
        {label}
      </StyledText>
    );
  }, [label, labelTooltip]);

  const errorMessage = useMemo(() => {
    if (!shouldShowErrorMessage) {
      return;
    }

    let message;
    if (meta.error) {
      if (typeof meta.error === 'string') {
        message = t(meta.error);
      } else if (typeof meta.error === 'object') {
        const error = meta.error as any;
        const values: { [key: string]: string } = {};

        if (error.values) {
          Object.keys(error.values).forEach((key) => {
            values[key] = t(error.values[key], error.values[key]) as string;
          });
        }

        message = t(error.key, values);
      }
    }

    return message;
  }, [shouldShowErrorMessage, meta.error]);

  const innerComponentProps = useMemo(() => {
    let result: InnerComponentModel = {
      name,
      size,
      onBlur: handleBlur,
      value: privateValue,
      onChange: handleChange,
      placeholder,
      disabled: isSubmitting || disabled || status === DISABLED_FORM_STATUS,
      validationRules: fieldValidationRules,
    };

    if (additionalProps) {
      result = { ...result, ...additionalProps };
    }

    // TODO: investigate and come up with better solution for this
    const componentType: any = React.createElement(Component as any).type;

    // Works only for antd Checkbox component
    if (componentType.__ANT_CHECKBOX) {
      result = { ...result, checked: privateValue };
    }

    return result;
  }, [
    isSubmitting,
    disabled,
    privateValue,
    additionalProps,
    status,
    placeholder,
    fieldValidationRules,
  ]);

  return (
    <InnerFormItem
      label={itemLabel}
      help={errorMessage}
      component={Component}
      labelAlign={labelAlign}
      innerComponentProps={innerComponentProps}
      labelCol={labelCol || DEFAULT_LABEL_CONFIG}
      style={!shouldShowErrorMessage ? DEFAULT_INPUT_WRAPPER_STYLE : undefined}
      validateStatus={
        meta.error ? VALIDATE_STATUS_ENUM.ERROR : VALIDATE_STATUS_ENUM.SUCCESS
      }
    />
  );
}

const InnerFormItem = memo(
  ({
    innerComponentProps,
    component: Component,
    ...rest
  }: InnerFormItemProps) => {
    return (
      <StyledFormItem {...rest} colon={false}>
        <Component {...innerComponentProps} />
      </StyledFormItem>
    );
  },
);

const StyledFormItem = styled(FormItem)`
  .ant-form-item-label {
    display: flex;
    label {
      width: 100%;
      min-height: 28px;
      height: unset;
      text-align: start;

      .anticon-info-circle {
        margin-left: 8px;
        margin-bottom: 6px;
      }
    }
  }

  // Make first letter capital in error message
  .ant-form-item-control {
    .ant-form-item-explain {
      text-transform: lowercase;
    }
    .ant-form-item-explain:first-letter {
      text-transform: uppercase;
    }
  }
`;

const StyledText = styled(Text)`
  flex: 1;
`;

export default FormField;
