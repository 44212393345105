import React, { ChangeEvent, useCallback } from 'react';

// helpers
import { debounce } from 'lodash';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from 'constants/global';

// components
import Input, { InputProps } from '@core_components/Input';

export interface SearchInputProps extends InputProps {
  onSearch: (searchQuery: string) => void;
}

const SearchInput = ({ onSearch, ...rest }: SearchInputProps) => {
  const debouncedFetchData = useCallback(
    debounce(onSearch, AUTOCOMPLETE_DEBOUNCE_DELAY),
    [],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    debouncedFetchData(e.target.value);
  };

  return <Input onChange={handleChange} {...rest} />;
};

export default SearchInput;
