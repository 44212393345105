export interface IFileTypes {
  [key: string]: {
    shortType: string;
  };
}

export const FILE_TYPES: IFileTypes = {
  'application/pdf': {
    shortType: 'PDF',
  },
  'application/xml': {
    shortType: 'XML',
  },
  'application/vnd.ms-excel': {
    shortType: 'MS Excel (.xls)',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    shortType: 'MS Excel (.xlsx)',
  },
  'application/vnd.ms-powerpoint': {
    shortType: 'MS PowerPoint (.ppt)',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    shortType: 'MS PowerPoint (.pptx)',
  },
  'application/msword': {
    shortType: 'MS Word (.doc)',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    shortType: 'MS Word (.docx)',
  },
  'text/csv': {
    shortType: 'CSV',
  },
  'text/tab-separated-values': {
    shortType: 'TSV',
  },
  'text/plain': {
    shortType: 'TXT (.txt)',
  },
};

export const ALLOWED_UPLOAD_TYPES =
  '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf';
export const ALLOWED_ATTACHMENT_DOCUMENT_TYPES =
  '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.jpg,.jpeg,.png';

export const ALLOWED_VERIFICATION_DOCUMENT_TYPES = '.jpg,.png,.pdf';

export enum UploadStatus {
  WAIT = 'WAIT',
  UPLOADING = 'UPLOADING',
  DONE = 'DONE',
  FAILED = 'FAILED',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export const MAX_TAGS_COUNT = 20;
export const MAX_DOCUMENT_NAME_LENGTH_COUNT = 150;

export const MESSAGE_ATTACHMENT_TAGS = ['attachment', 'client', 'message'];
export const RESET_AUTHENTICATION_REQUEST_TAGS = ['request authentication'];
export const MAX_UPLOAD_DOCUMENT_SIZE = 52428800;
