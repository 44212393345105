import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useTranslation from '../../../../../../../../hooks/useTranslation';
import relationshipsAPI from '../../../../../../../../api/relationships/relationshipsAPI';
import { IEntity } from '../../../../../../../../typings/application/entity';
import { ContactModel } from '../../../../../../../../typings/application/contact';
import { onboardingAPI } from 'api/onboarding/onboardingAPI';
import { message, Modal } from 'antd';
import { ContactHelpers } from 'helpers/crm/contact';
import { RelationshipHelpers } from '../../../../../../../../helpers/crm/relationship';
import { onboardingAPIAdapter } from 'apiAdapters/onboarding/onboardingAPIAdapter';
import { GraphEdge, GraphNode } from '../../../../../../../Charts/GraphChart';
import { AdditionalFieldHelpers } from '../../../../../../../../helpers/additionalField';
import { relationshipsAPIAdapter } from '../../../../../../../../apiAdapters/relationships/relationshipsAPIAdapter';
import { AdditionalFieldFormItemModel } from '../../../../../../FormComponents/AdditionalField';

// constants
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from '../../../../../../../../enums/onboarding/crm';

// components
import Text from '@core_components/Text';
import EditNodeModalDialog, {
  FormValuesModel,
} from '../../../../../../../ModalDialogs/TemplateModalDialogs/Onboarding/EditNodeModalDialog';
import { Trans } from 'react-i18next';

interface IProps {
  isVisible: boolean;
  isViewOnly: boolean;
  closeCallback: (wasCreated?: boolean) => void;
  onHandoffRequestSend: () => void;
  data: null | {
    node: GraphNode;
    edges: GraphEdge[];
    clientGroupId: string;
    activeScopeId: string;
    allNodes: GraphNode[];
  };
}

const EditNodeModal = ({
  isVisible,
  closeCallback,
  isViewOnly,
  data,
  onHandoffRequestSend,
}: IProps) => {
  const { t } = useTranslation(['onboarding', 'common']);
  const [canSendHandoffRequest, setCanSendHandoffRequest] = useState(false);

  const modalTitle = useMemo(() => {
    if (!data?.node) {
      return '';
    }

    return data.node.entryType === OnboardingEntryTypes.Contact
      ? t('node_modal.title.for_contact')
      : t('node_modal.title.for_organization');
  }, [data]);

  useEffect(() => {
    if (isVisible && data?.node) {
      setCanSendHandoffRequest(
        data.node.entryType == OnboardingEntryTypes.Contact &&
          data.node.metadata.handoffCanBeSent,
      );
    } else {
      setCanSendHandoffRequest(false);
    }
  }, [data, isVisible]);

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!data || !isVisible) {
      return null;
    }

    const { node, edges, clientGroupId, allNodes, activeScopeId } = data;

    const hashNodes = allNodes.reduce<Record<string, GraphNode>>((acc, cur) => {
      acc[cur.id] = { ...cur };
      return acc;
    }, {});

    const entity = node.model as IEntity;
    const contact = node.model as ContactModel;

    const relationships = node.isApplicantOrganization
      ? []
      : edges.map((e) => {
          const { relationship, relationshipTemplate } = e;
          const isApplicantRelationship =
            (hashNodes[e.target].isApplicantContact ||
              hashNodes[e.target].isApplicantOrganization) &&
            (hashNodes[e.source].isApplicantContact ||
              hashNodes[e.source].isApplicantOrganization);

          const parentNode = hashNodes[e.target];
          const childNode = hashNodes[e.source];

          const additionalFields: AdditionalFieldFormItemModel[] =
            AdditionalFieldHelpers.filterAndMapAdditionalFieldsFromTemplateToFormModel(
              relationshipTemplate,
              childNode.entryType,
              relationship,
            );

          return {
            customId: relationship._id,
            id: relationship._id,
            isApplicantRelationship,

            relationshipTemplateId: relationshipTemplate._id,

            relationshipTemplate: {
              id: relationshipTemplate._id,
              childRelationshipLabel: RelationshipHelpers.getRelationshipName(
                {
                  parentName: relationshipTemplate.parentName,
                  childName: relationshipTemplate.childName,
                },
                RelationshipTrackTypes.Child,
              ),
              initialOption: {
                id: relationshipTemplate._id,
                label: RelationshipHelpers.getRelationshipName(
                  {
                    parentName: relationshipTemplate.parentName,
                    childName: relationshipTemplate.childName,
                  },
                  RelationshipTrackTypes.Parent,
                ),
              },
            },

            parent: {
              id: childNode.id,
              type: childNode.entryType,
              autocompleteInitialValue: {
                id: childNode.id,
                label: childNode.label,
              },
            },

            child: {
              id: parentNode.id,
              type: parentNode.entryType,
              label: parentNode.label,
              autocompleteInitialValue: {
                id: parentNode.id,
                label: parentNode.label,
              },
            },

            additionalFields,
          };
        });

    return {
      clientGroupId,
      activeScopeId,
      clientUserId: null,
      disableNodeForm: node.sharedData,
      hideRelationshipsSection: node.isApplicantOrganization,

      entryType: node.entryType,

      parentForNewRelationships: {
        id: node.id,
        type: node.entryType,
        autocompleteInitialValue: {
          id: node.id,
          label: node.label,
          model: node.model,
        },
      },

      contact:
        node.entryType === OnboardingEntryTypes.Contact
          ? {
              isPermissionsSelectAvailable: false,
              id: contact._id,
              firstName: contact.firstName,
              middleName: contact.middleName,
              lastName: contact.lastName,
              isPEP: typeof contact.isPEP === 'boolean' ? contact.isPEP : null,
              pepInformation: contact.pepInformation,
              isOnlineUser: !!contact.onlineAccess,
              adminPermissionType: contact.onlineAccess
                ? contact.onlineAccess.adminPermissionType
                : null,
              accountPermissions: contact.onlineAccess
                ? contact.onlineAccess.permissionType
                : null,
              phone: contact.phoneNumbers.length
                ? contact.phoneNumbers.find((e) => e.isPrimary)?.number || ''
                : '',
              email: contact.emails.length
                ? contact.emails.find((e) => e.isPrimary)?.address || ''
                : '',
              disableEmail: node.isApplicantContact,
              disablePhoneNumber: node.isApplicantContact,
            }
          : null,

      organization:
        node.entryType === OnboardingEntryTypes.Organization
          ? {
              id: entity._id,
              name: entity.names[0].name,
              isRegulated:
                typeof entity.isRegulated === 'boolean'
                  ? entity.isRegulated
                  : null,
              regulatedCountries: entity.regulationCountry,
            }
          : null,
      keyRelationshipTemplateId: node.model.keyRelationshipTemplateId as string,
      clientGroupEntryType: OnboardingEntryTypes.Organization,
      relationships,
    };
  }, [isVisible, data]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (initialFormValues) {
      const formattedRequestBody =
        relationshipsAPIAdapter.generateRequestBodyForEditNode(
          values,
          initialFormValues,
          true,
        );

      // Edge case for updating applicant organization
      // Applicant organization doesn't have any relationships, but the modal dialog is visible
      // So to be able to update applicant organization we have another API
      if (formattedRequestBody.operations.length) {
        await relationshipsAPI.updateRelationships(formattedRequestBody);
      } else {
        if (
          values.entryType === OnboardingEntryTypes.Organization &&
          values.organization
        ) {
          const formattedOrgData =
            onboardingAPIAdapter.updateOrganizationShortInfo(values);
          await onboardingAPI.updateOrganizationShortInfo(
            values.organization?.id as string,
            formattedOrgData,
          );
        }
      }

      message.success(t('structure.relationships.edit_modal.success_edit'));
    }
  };

  const onHandoffClick = async () => {
    if (data && initialFormValues) {
      const contact = ContactHelpers.getFormattedContactName(
        initialFormValues.contact?.firstName || '',
        initialFormValues.contact?.lastName || '',
      );

      Modal.confirm({
        title: t('node_modal.handoff_application.confirmation_modal.title'),
        width: 600,
        icon: null,
        closable: true,
        maskClosable: true,
        content: (
          <div>
            <Text gutterBottom>
              <Trans
                t={t}
                values={{ contact }}
                i18nKey="node_modal.handoff_application.confirmation_modal.description_line_1"
                components={[
                  <Text key="contact_name" weight="semi-bold" variant="span" />,
                ]}
              />
            </Text>
            <Text gutterBottom>
              <Trans
                t={t}
                values={{ contact }}
                i18nKey="node_modal.handoff_application.confirmation_modal.description_line_2"
                components={[
                  <Text key="contact_name" weight="semi-bold" variant="span" />,
                ]}
              />
            </Text>
            <Text gutterBottom>
              {t(
                'node_modal.handoff_application.confirmation_modal.description_line_3',
              )}
            </Text>
          </div>
        ),
        okText: t('send', { ns: 'common' }),
        async onOk() {
          await onboardingAPI.handoffApplicationToContact(data.node.id);
          message.success(
            t('node_modal.handoff_application.success_submit_message'),
          );
          setCanSendHandoffRequest(false);
          onHandoffRequestSend();
        },
      });
    }
  };

  return (
    <EditNodeModalDialog
      disabled={isViewOnly}
      title={modalTitle}
      onSubmit={handleSubmit}
      isVisible={isVisible}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
      onHandoffClick={canSendHandoffRequest ? onHandoffClick : undefined}
    />
  );
};

export default EditNodeModal;
