import React, { useState } from 'react';

// helpers
import useTranslation from '../../../../hooks/useTranslation';
import ErrorHandlerService from 'services/error-handler/service';
import { message } from 'antd';
import { clientGroupsAPI } from '../../../../api/crm/clientGroupsAPI';

// components
import SubmitForReviewErrorsModalDialog from 'components/ModalDialogs/TemplateModalDialogs/Onboarding/SubmitForReviewErrorsModalDialog';
import { default as TemplateSubmitConfirmationModal } from '../../../../components/ModalDialogs/TemplateModalDialogs/AccountManagement/SubmitConfirmationModal';

interface IProps {
  visible: boolean;
  closeCallback: (wasSubmitted?: boolean) => void;
}

const SubmitConfirmationModal = ({ visible, closeCallback }: IProps) => {
  const { t } = useTranslation('account_management');
  const [errors, setErrors] = useState<any[] | null>(null);

  const onSubmit = async () => {
    try {
      await clientGroupsAPI.submitForReview();
      message.success(
        t('success_submit_for_account_management_review_message'),
      );
      closeCallback(true);
    } catch (err: any) {
      closeCallback();
      if (err?.response?.data?.details?.details?.errors?.length) {
        setErrors(err.response.data.details.details.errors);
      } else {
        ErrorHandlerService.handleError(err);
      }
    }
  };

  return (
    <>
      <TemplateSubmitConfirmationModal
        isVisible={visible}
        closeCallback={closeCallback}
        onSubmit={onSubmit}
      />
      <SubmitForReviewErrorsModalDialog
        isVisible={!!errors}
        closeCallback={() => setErrors(null)}
        errors={errors}
      />
    </>
  );
};

export default SubmitConfirmationModal;
