import React, { useMemo, useState } from 'react';

// helpers
import { GraphData, NodeStatuses } from 'components/Charts/GraphChart';

// components
import NodesChangesTable, {
  Record,
  NodeChangeTypes,
} from 'components/Tables/TableTemplates/AccountManagement/NodesChangesTable';

interface IProps {
  graph: GraphData;
}

function mapNodeStatusToChangeType(
  status: NodeStatuses,
): NodeChangeTypes | null {
  switch (status) {
    case NodeStatuses.New:
      return 'create';
    case NodeStatuses.Updated:
      return 'update';
    case NodeStatuses.Deleted:
      return 'delete';
    default:
      return null;
  }
}

const NodesChangesSection = ({ graph }: IProps) => {
  const [page, setPage] = useState(1);
  const formattedNodes = useMemo<Record[]>(() => {
    const nodesWithSomeUpdates = graph.nodes.filter(
      (node) => node.status !== NodeStatuses.NoChanges,
    );

    if (!nodesWithSomeUpdates.length) {
      return [];
    } else {
      return nodesWithSomeUpdates.map((node) => ({
        name: node.label,
        type: node.typeLabel,
        change: node.status ? mapNodeStatusToChangeType(node.status) : null,
      }));
    }
  }, [graph.nodes]);

  if (!formattedNodes.length) {
    return null;
  }

  return (
    <NodesChangesTable
      data={formattedNodes}
      total={formattedNodes.length}
      current={page}
      onPaginationChange={setPage}
    />
  );
};

export default NodesChangesSection;
