import React, { memo } from 'react';

// helpers
import styled, { keyframes } from 'styled-components';
import { colorsTheme } from '../../../../resources/theme/styled/colors';

// components
import IconSVG from '@core_components/IconSVG';
import { ReactComponent as SpinnerIcon } from '../../../../resources/icons/remix-icons/loader-4-line.svg';

interface IProps {
  color?: string;
}

const Spinner = memo(({ color = colorsTheme.colorWhite }: IProps) => {
  return (
    <LoaderWrapper>
      <IconSVG component={SpinnerIcon} color={color} />
    </LoaderWrapper>
  );
});

const spinAnimation = keyframes`
 100% { transform: rotate(360deg); }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    animation-name: ${spinAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;

export default Spinner;
