import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from '../../../../../hooks/useFetch';
import useUserAccess from 'hooks/useUserAccess';
import { getBadges } from 'redux/actions/app';
import { StateModel } from '../../../../../redux/reducers';
import { ApprovalHelpers } from 'helpers/approval/approval';
import { transactionsAPI } from '../../../../../api/accounting/transactionsAPI';
import { transactionsAdapter } from 'apiAdapters/accounting/transactionsAdapter';
import { IApprovalMatrixRecord } from '../../../../../typings/accounting/transaction';
import { useDispatch, useSelector } from 'react-redux';
import { RequiredPropsForModalDialogModel } from '@core_components/ModalDialog';
import { AccountManagementApprovalStatuses } from '../../../../../enums/approvalManagement/approvalManagement';

// components
import TransactionDetailsModalDialog, {
  TransactionData,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/Accounts/TransactionDetailsModalDialog';

interface IProps extends RequiredPropsForModalDialogModel {
  transactionId: number | null;
  onApproveOrReject: (transactionId: number) => void;
  onCancelTransaction?: (transactionId: number) => void;
}

const ViewPendingTransactionModal = ({
  isVisible,
  closeCallback,
  transactionId,
  onApproveOrReject,
  onCancelTransaction,
}: IProps) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector<StateModel, string>(
    (state) => state.auth.profileData?._id || '',
  );
  const [hasReviewAccess] = useUserAccess([
    'AccountManagement_TransactionRules_Workflow_ApproveReject',
  ]);
  const isTwoFactorVerificationEnabled = useSelector<StateModel, boolean>(
    (state) => state.auth.isTwoFactorVerificationEnabled,
  );
  const [canApproveOrReject, setCanApproveOrReject] = useState(false);
  const [transactionApprovals, setTransactionApprovals] = useState<
    IApprovalMatrixRecord[][] | null
  >(null);
  const [updateTransactionTrigger, updateTransaction] = useState<any>();

  const { response: fetchTransactionResponse } = useFetch(
    () =>
      transactionId
        ? transactionsAPI.fetchTransactionById(transactionId)
        : null,
    [transactionId, updateTransactionTrigger],
  );

  const { response: fetchTransactionApprovalsResponse } = useFetch(
    () =>
      fetchTransactionResponse?.workflowId
        ? transactionsAPI.fetchTransactionApprovalsStatus(
            fetchTransactionResponse?.workflowId,
          )
        : null,
    [fetchTransactionResponse?.workflowId, updateTransactionTrigger],
  );

  useEffect(() => {
    if (fetchTransactionApprovalsResponse) {
      const approvalsArray =
        fetchTransactionApprovalsResponse?.approvalProgressStateSets
          ? fetchTransactionApprovalsResponse.approvalProgressStateSets.map(
              (e) => e.progressStateItems,
            )
          : [];

      const isUserPendingApproval = ApprovalHelpers.userHasPendingApproval(
        currentUserId,
        approvalsArray,
      );
      setTransactionApprovals(approvalsArray);
      setCanApproveOrReject(
        !!(
          fetchTransactionApprovalsResponse.status ===
            AccountManagementApprovalStatuses.PendingApproval &&
          hasReviewAccess &&
          isUserPendingApproval
        ),
      );
    }
  }, [fetchTransactionApprovalsResponse, hasReviewAccess]);

  const handleOnReview = () => {
    updateTransaction({});
    onApproveOrReject(transactionId as number);
    dispatch(getBadges());
  };

  const handleCancelTransaction = async () => {
    if (transactionId) {
      await transactionsAPI.cancelTransaction(transactionId);
      dispatch(getBadges());
      onCancelTransaction && onCancelTransaction(transactionId);
    }
  };

  const initialData = useMemo<TransactionData | null>(() => {
    let result: TransactionData | null = null;

    if (fetchTransactionResponse && transactionApprovals && transactionId) {
      const formattedTransaction =
        transactionsAdapter.formatJournalEntryForTransactionDetailsModal(
          fetchTransactionResponse,
        );

      result = {
        ...formattedTransaction,
        transactionId,
        journalEntryId: null,
        approvalWorkflowId: fetchTransactionResponse.workflowId,
        transactionApprovals: fetchTransactionResponse.isSender
          ? transactionApprovals
          : null,
      };
    }

    return result;
  }, [fetchTransactionResponse, transactionApprovals, transactionId]);

  return (
    <TransactionDetailsModalDialog
      onReview={
        canApproveOrReject && isTwoFactorVerificationEnabled
          ? handleOnReview
          : undefined
      }
      isVisible={isVisible}
      initialData={initialData}
      isTFAEnabled={isTwoFactorVerificationEnabled}
      closeCallback={closeCallback}
      onCancelTransaction={handleCancelTransaction}
    />
  );
};

export default ViewPendingTransactionModal;
