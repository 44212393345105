import React, { useMemo } from 'react';

// helpers
import useUserAccess from 'hooks/useUserAccess';
import useTranslation from 'hooks/useTranslation';
import { TransactionTypes } from 'enums/approvalManagement/approvalManagement';
import { SelectOption, SelectProps } from '@core_components/Select';

// components
import FormSelect from '@common_components/Form/FormSelect';

export type IProps = SelectProps;

const TransactionTypeSelect = (props: IProps) => {
  const { t } = useTranslation('accounts');
  const [
    hasInternalTransferAccess,
    hasWireTransferAccess,
    hasBlockchainTransferAccess,
  ] = useUserAccess([
    'Transfers_InternalTransfer_Create',
    'Transfers_WireTransfer_Create',
    'Transfers_BlockchainTransfer_Create',
  ]);

  const options = useMemo<SelectOption[]>(() => {
    return [
      {
        hidden: !hasInternalTransferAccess,
        id: TransactionTypes.InternalTransfer,
        label: t(
          `types.${TransactionTypes[TransactionTypes.InternalTransfer]}`,
        ),
      },

      {
        hidden: !hasWireTransferAccess,
        id: TransactionTypes.WireTransfer,
        label: t(`types.${TransactionTypes[TransactionTypes.WireTransfer]}`),
      },

      {
        hidden: !hasBlockchainTransferAccess,
        id: TransactionTypes.BlockchainTransfer,
        label: t(
          `types.${TransactionTypes[TransactionTypes.BlockchainTransfer]}`,
        ),
      },
    ];
  }, [
    t,
    hasInternalTransferAccess,
    hasWireTransferAccess,
    hasBlockchainTransferAccess,
  ]);

  return <FormSelect {...props} options={options} />;
};

export default TransactionTypeSelect;
