import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from '../../../../../hooks/useTranslation';
import { useField } from 'formik';
import { useDispatch } from 'react-redux';
import { setIsPhoneVerifiedStatus } from 'redux/actions/auth';

// constants
import { PhoneNumberTypes } from '../../../../../enums/onboarding/crm';

// components
import FormField from '@core_components/FormField';
import DeleteButton from '../../../../DesignSystem/Common/Buttons/DeleteButton';
import DivAlignCenter from 'components/Additional/DivAlignCenter';
import PhoneTypeSelect from '../../../FormComponents/SelectInputs/CRM/PhoneTypeSelect';
import VerifyPhoneNumber from './VerifyPhoneNumber';
import PrimaryStatusText from '../../../../Typography/PrimaryStatusText';
import HideIfDisabledForm from '../../../HideIfDisabledForm';
import VerifiedStatusText from '../../../../Typography/VerifiedStatusText';
import FormInputPhoneNumber from '@common_components/Form/FormInputPhoneNumber';
import { Col, Row } from 'antd';

interface IProps {
  fieldName: string;
  canVerify?: boolean;
  removeCallback?: () => void;
}

export interface FormValuesModel {
  type: PhoneNumberTypes | null;
  value: string;
  primary: {
    status: boolean;
    canEdit: boolean;
  };
  isVerified?: boolean;
}

const PhoneNumberForm = ({ canVerify, fieldName, removeCallback }: IProps) => {
  const { t } = useTranslation('onboarding');
  const dispatch = useDispatch();
  const [field, , helpers] = useField<FormValuesModel>(fieldName);

  const primary = field.value.primary;
  const gridSizes = { xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };

  const onVerify = () => {
    helpers.setValue({ ...field.value, isVerified: true });
    dispatch(setIsPhoneVerifiedStatus(true));
  };

  return (
    <Row gutter={[16, 0]}>
      <Col {...gridSizes}>
        <FormField
          label={t(
            'applicant_information.form_fields.individual.phone_number.type',
          )}
          name={`${fieldName}.type`}
          component={PhoneTypeSelect}
          disabled={!primary.canEdit}
        />
      </Col>

      <Col flex="1 1">
        <FormField
          label={t(
            'applicant_information.form_fields.individual.phone_number.phone_number',
          )}
          name={`${fieldName}.value`}
          component={FormInputPhoneNumber}
          disabled={!primary.canEdit}
        />
      </Col>

      <DivAlignCenter>
        <StyledHelperCol>
          {typeof field.value.isVerified === 'boolean' && primary.status && (
            <VerifiedStatusText isVerified={field.value.isVerified} />
          )}
        </StyledHelperCol>

        <StyledHelperCol>
          {primary.status ? (
            <PrimaryStatusText />
          ) : (
            removeCallback && (
              <HideIfDisabledForm hideIfSubmitting>
                <DeleteButton onClick={removeCallback} />
              </HideIfDisabledForm>
            )
          )}
        </StyledHelperCol>
        {canVerify && (
          <StyledHelperCol>
            <VerifyPhoneNumber fieldName={fieldName} onVerify={onVerify} />
          </StyledHelperCol>
        )}
      </DivAlignCenter>
    </Row>
  );
};

const StyledHelperCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

export default PhoneNumberForm;
