import React, { useEffect, useMemo, useState } from 'react';

// helpers
import useFetch from 'hooks/useFetch';
import useQueryParam from 'hooks/useQueryParam';
import useTranslation from 'hooks/useTranslation';
import { styled } from 'styled-components';
import { changeView } from 'redux/actions/view';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transactionsAPI } from 'api/accounting/transactionsAPI';

// components
import LoadingWrapper from 'components/WrapperComponents/LoadingWrapper';
import SubmitSingleTransactionForm from 'modules/Transfers/WireTransfer/SubmitSingleTransactionForm';
import SubmitMultipleTransactionsForm from 'modules/Transfers/WireTransfer/SubmitMultipleTransactionsForm';
import { Switch } from 'antd';

const WireTransfer = () => {
  const { t } = useTranslation('transfers');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const transactionIdFromQuery = useQueryParam({ param: 'transactionId' });
  const journalEntryIdFromQuery = useQueryParam({ param: 'journalEntryId' });
  const [selectedView, setSelectedView] = useState<'single' | 'multiple'>(
    'single',
  );

  const { response, loading, error } = useFetch(
    () =>
      journalEntryIdFromQuery || transactionIdFromQuery
        ? transactionsAPI.fetchWireTransferPrefillData(
            journalEntryIdFromQuery,
            transactionIdFromQuery,
          )
        : null,
    [journalEntryIdFromQuery, transactionIdFromQuery],
  );

  useEffect(() => {
    if (journalEntryIdFromQuery || transactionIdFromQuery) {
      if (error || response) {
        navigate({ search: '' });
      }
    }
  }, [journalEntryIdFromQuery, transactionIdFromQuery, error, response]);

  const handleSwitchChange = (checked: boolean) => {
    if (checked) {
      dispatch(changeView(() => setSelectedView('multiple')));
    } else {
      dispatch(changeView(() => setSelectedView('single')));
    }
  };

  const content = useMemo(() => {
    switch (selectedView) {
      case 'single':
        return <SubmitSingleTransactionForm prefillData={response} />;

      case 'multiple':
        return <SubmitMultipleTransactionsForm />;
    }
  }, [selectedView, response]);

  return (
    <LoadingWrapper loading={loading}>
      <StyledSwitchWrapper>
        {t('international_transfer.switch_between_single_and_multiple')}
        <StyledSwitch
          checked={selectedView == 'multiple'}
          onChange={handleSwitchChange}
        />
      </StyledSwitchWrapper>
      <div>{content}</div>
    </LoadingWrapper>
  );
};

const StyledSwitchWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.marginSm};
`;

const StyledSwitch = styled(Switch)`
  margin-left: ${({ theme }) => theme.marginSm};
`;

export default WireTransfer;
