import React, { useMemo } from 'react';

// helpers
import useTranslation from '../../../../../hooks/useTranslation';
import { message } from 'antd';
import { StateModel } from '../../../../../redux/reducers';
import { useSelector } from 'react-redux';
import { IClientGroupUser } from '../../../../../typings/approvalManagement/accountManagement';
import { userManagementAPINew } from '../../../../../api/accountManagement/userManagementAPINew';
import {
  AccountUserPermissionTypes,
  AdministrationPermissionTypes,
  ApplicationStatuses,
} from '../../../../../enums/onboarding/crm';

// components
import RelationshipPermissionModalDialog, {
  FormValuesModel,
} from '../../../../../components/ModalDialogs/TemplateModalDialogs/Onboarding/RelationshipPermissionModalDialog';

interface IProps {
  isVisible: boolean;
  clientGroupUser: IClientGroupUser | null;
  closeCallback: (wasUpdated?: boolean) => void;
}

const UpdatePermissionsModal = ({
  isVisible,
  clientGroupUser,
  closeCallback,
}: IProps) => {
  const { t } = useTranslation('account_management');
  const applicationStatus = useSelector<StateModel, ApplicationStatuses | null>(
    (state) => state.applications.activeApplicationStatus,
  );
  const isApplicationUnderReview =
    applicationStatus === ApplicationStatuses.InAccountManagementReview ||
    applicationStatus === ApplicationStatuses.InReview;

  const initialFormValues = useMemo<FormValuesModel | null>(() => {
    if (!clientGroupUser) {
      return null;
    }

    return {
      accountPermissions: clientGroupUser.toUpdate
        ? clientGroupUser.toUpdate.permissionType
        : clientGroupUser.permissionType,
      adminPermissionType: clientGroupUser.toUpdate
        ? clientGroupUser.toUpdate.adminPermissionType
        : clientGroupUser.adminPermissionType,
      showLimitedPermission: clientGroupUser.toUpdate
        ? clientGroupUser.toUpdate.adminPermissionType ===
          AdministrationPermissionTypes.LimitedAdmin
        : clientGroupUser.adminPermissionType ===
          AdministrationPermissionTypes.LimitedAdmin,
    };
  }, [clientGroupUser]);

  const handleSubmit = async (values: FormValuesModel) => {
    if (clientGroupUser) {
      await userManagementAPINew.updateClientPermissions(clientGroupUser._id, {
        permissions: {
          adminPermissionType:
            !values.adminPermissionType ||
            values.adminPermissionType ===
              AdministrationPermissionTypes.LimitedAdmin
              ? undefined
              : values.adminPermissionType,
          accountPermission:
            values.accountPermissions as AccountUserPermissionTypes,
        },
      });

      message.success(
        t('client_profile_details.update_permissions.success_update_message'),
      );
    }
  };

  return (
    <RelationshipPermissionModalDialog
      disabled={isApplicationUnderReview}
      title={t('client_profile_details.update_permissions.title')}
      isVisible={isVisible}
      onSubmit={handleSubmit}
      closeCallback={closeCallback}
      initialValues={initialFormValues}
    />
  );
};

export default UpdatePermissionsModal;
