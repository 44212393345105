import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { useFormikContext } from 'formik';
import { FormValuesModel } from '../..';

// components
import Text from '@core_components/Text';
import WireTransferGridForm from 'components/Forms/TemplateForms/Transfers/WireTransferGridForm';

const ConfirmationStep = () => {
  const { t } = useTranslation('transfers');
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Text gutterBottom>
        {t(
          'international_transfer.submit_multiple_modal.confirmation_step_description',
          { count: values.transactions.length },
        )}
      </Text>
      <WireTransferGridForm viewOnly />
    </>
  );
};

export default ConfirmationStep;
