import React, { useEffect, useState } from 'react';

// helpers
import useFetch from '../../hooks/useFetch';
import useQueryParam from '../../hooks/useQueryParam';
import useTranslation from '../../hooks/useTranslation';
import { IEntity } from '../../typings/application/entity';
import { RoutePaths } from 'routes/routes';
import { StateModel } from '../../redux/reducers';
import { useSelector } from 'react-redux';
import { ContactModel } from '../../typings/application/contact';
import { IApplication } from '../../typings/application/applications';
import { EntityHelpers } from '../../helpers/crm/entity';
import { ContactHelpers } from '../../helpers/crm/contact';
import { OnboardingEntryTypes } from '../../enums/onboarding/crm';
import { useMainLayoutContext } from 'modules/Layouts/MainLayout/MainLayoutContext';
import { userManagementAPINew } from '../../api/accountManagement/userManagementAPINew';

// components
import ManageKYC from 'components/Additional/CRM/ManageKYC';
import LoadingWrapper from '../../components/WrapperComponents/LoadingWrapper';
import OnboardingFormSection from '../../modules/AccountManagement/ClientProfile/ClientDetails/OnboardingFormSection';

const ClientDetails = () => {
  const { t } = useTranslation('account_management');
  const { setPageLayoutOptions, clearContext } = useMainLayoutContext();
  const [updateDataTrigger, updateData] = useState({});
  const clientId = useQueryParam({
    param: 'id',
    noParamRedirect: RoutePaths.AccountManagement_ClientProfile,
  });
  const clientType = useQueryParam({
    param: 'type',
    noParamRedirect: RoutePaths.AccountManagement_ClientProfile,
  });

  const activeApplication = useSelector<StateModel, IApplication | null>(
    ({ applications }) => applications.activeApplication,
  );

  const { response, loading } = useFetch(
    () =>
      clientId && clientType
        ? userManagementAPINew.fetchClientDetails(
            clientId,
            clientType as OnboardingEntryTypes,
          )
        : null,
    [clientId, clientType, updateDataTrigger],
  );

  useEffect(() => {
    if (response) {
      const { item } = response;

      const entity = item as IEntity;
      const contact = item as ContactModel;

      const name =
        clientType === OnboardingEntryTypes.Organization
          ? EntityHelpers.getEntityNameByNameType(entity.names)
          : ContactHelpers.getFormattedContactName(
              contact.firstName,
              contact.lastName,
              contact.middleName,
            );

      setPageLayoutOptions((prev) => ({
        ...prev,
        title: `${t('client_profile_details.title')}: ${name}`,
      }));
    }

    return () => {
      clearContext();
    };
  }, [response, clientType, setPageLayoutOptions, clearContext]);

  return (
    <LoadingWrapper loading={loading}>
      {response && (
        <>
          <ManageKYC
            data={{
              nodeId: response.item._id,
              onboardingProcessId: response.onboardingProcess?._id || null,
              isOnboardingProcessRemovable:
                response.metadata.isOnboardingProcessRemovable,
              isOnboardingProcessCreatable:
                response.metadata.isOnboardingProcessCreatable,
            }}
            onUpdate={() => updateData({})}
          />
          <OnboardingFormSection
            item={response.item}
            itemType={clientType as OnboardingEntryTypes}
            onboardingStatus={
              response.onboardingProcess
                ? {
                    ...response.onboardingProcess,
                    applicationId: activeApplication?._id as string,
                  }
                : null
            }
          />
        </>
      )}
    </LoadingWrapper>
  );
};

export default ClientDetails;
