import React from 'react';

// helpers
import styled from 'styled-components';
import useUserAccess from '../../../../../../../hooks/useUserAccess';
import useTranslation from '../../../../../../../hooks/useTranslation';
import { DateHelpers } from 'helpers/date';
import { useFormikContext } from 'formik';
import { TransferTemplateStatuses } from 'enums/accounting/templates';
import { INewTransferTemplateFormValues } from 'typings/accounting/transfer';

// components
import Text from '@core_components/Text';
import FormField from '@core_components/FormField';
import FormInput from '@common_components/Form/FormInput';
import TransferTemplateStatus from 'components/Additional/Statuses/TransferTemplateStatus';
import TemplatePrivateStatusRadioSelect from '../../../../../../Forms/FormComponents/SelectInputs/Accounting/TemplatePrivateStatusRadioSelect';
import TransferTemplateTypeSelect, {
  IProps as TransferTemplateTypeSelectProps,
} from '../../../../../../Forms/FormComponents/SelectInputs/Transfers/TransferTemplateTypeSelect';
import { Col, Divider, Row } from 'antd';

interface IProps {
  disabledTemplateTypeField?: boolean;
  isEditMode?: boolean;
  isViewOnly?: boolean;
}

const GeneralTemplateDataSection = ({
  disabledTemplateTypeField,
  isEditMode,
  isViewOnly,
}: IProps) => {
  const { t } = useTranslation('transfers');
  const gridSizes = { xl: 4, lg: 4, md: 5, sm: 24, xs: 24 };

  const { values } = useFormikContext<INewTransferTemplateFormValues>();

  const [
    hasWireTemplateAccess,
    hasInternalTemplateAccess,
    hasBlockchainTemplateAccess,
  ] = useUserAccess([
    'Transfers_WireTransferTemplate_Create',
    'Transfers_InternalTransferTemplate_Create',
    'Transfers_BlockchainTransferTemplate_Create',
  ]);

  return (
    <>
      {isEditMode && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text>
                {t('transfer_templates.transfer_template_modal.id')}:&nbsp;{' '}
                {values.id}
              </Text>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text>
                {t('transfer_templates.transfer_template_modal.date')}:&nbsp;
                {DateHelpers.formatTimestampDateToString(
                  values.creationDate as number,
                )}
              </Text>
            </Col>
          </Row>
        </>
      )}

      {!values.status && isEditMode && <StyledDivider />}

      {values.status && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Text>
                {t('transfer_templates.transfer_template_modal.status')}:&nbsp;
                <TransferTemplateStatus status={values.status} />
              </Text>
            </Col>
          </Row>

          {values.invalidityReason &&
            values.status === TransferTemplateStatuses.Archived && (
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text>
                    {t('transfer_templates.transfer_template_modal.reason')}:{' '}
                    {values.invalidityReason}
                  </Text>
                </Col>
              </Row>
            )}

          <StyledDivider />
        </>
      )}
      <FormField
        name="templateName"
        component={FormInput}
        disabled={isViewOnly}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.template_name',
        )}
        labelCol={gridSizes}
      />

      {!isEditMode && (
        <FormField<TransferTemplateTypeSelectProps>
          name="templateType"
          component={TransferTemplateTypeSelect}
          label={t(
            'transfer_templates.transfer_template_modal.form_fields.template_type',
          )}
          labelCol={gridSizes}
          disabled={isViewOnly || disabledTemplateTypeField}
          additionalProps={{
            hideWireTemplateOption: !hasWireTemplateAccess,
            hideInternalTemplateOption: !hasInternalTemplateAccess,
            hideBlockchainTemplateOption: !hasBlockchainTemplateAccess,
          }}
        />
      )}

      <FormField
        name="permissions"
        component={TemplatePrivateStatusRadioSelect}
        label={t(
          'transfer_templates.transfer_template_modal.form_fields.permissions',
        )}
        disabled={isViewOnly || values.isPermissionsDisabled}
        labelCol={gridSizes}
        additionalProps={{
          optionType: 'button',
          buttonStyle: 'solid',
          style: { width: '100%' },
        }}
      />
    </>
  );
};

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.marginSm} 0;
`;

export default GeneralTemplateDataSection;
