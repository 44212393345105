import ErrorHandlerService, {
  ErrorFromServer,
} from 'services/error-handler/service';
import { clientMessagingAPI } from '../../api/messaging/clientMessagingAPI';

// Actions
export enum ActionType {
  SET_MESSAGES_STATUS = 'notifications/SET_MESSAGES_STATUS',
  CLEAN_UP = 'notifications/CLEAN_UP',
}

// Action creators
export const setMessagesStatus = (newMessagesCount: number) => ({
  type: ActionType.SET_MESSAGES_STATUS,
  payload: { newMessagesCount },
});

// Async
export const getMessagesStatus = () => async (dispatch: any) => {
  try {
    const { newMessagesCount } = await clientMessagingAPI.fetchMessagesStatus();
    dispatch(setMessagesStatus(newMessagesCount));
  } catch (error) {
    ErrorHandlerService.handleError(error as ErrorFromServer);
  }
};

export const cleanUpNotificationsReducer = () => ({
  type: ActionType.CLEAN_UP,
  payload: null,
});
