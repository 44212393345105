import React from 'react';

// helpers
import styled from 'styled-components';
import useTranslation from 'hooks/useTranslation';
import { FormValuesModel } from '../..';
import { useFormikContext } from 'formik';

// components
import Text from '@core_components/Text';
import EllipsisTooltip from 'components/Tooltips/EllipsisTooltip';
import DescriptionSection from '@core_components/DescriptionSection';
import { CardWrapper } from 'components/Additional/CardWrapper';

const BulkCompleted = () => {
  const { t } = useTranslation(['transfers', 'common']);
  const { values } = useFormikContext<FormValuesModel>();

  return (
    <>
      <Text>
        {values.transactions.length > 1
          ? t(
              'international_transfer.submit_multiple_modal.completed.description_plural',
              {
                numberOfApproved: values.result?.completed,
                total: values.transactions.length,
              },
            )
          : t(
              'international_transfer.submit_multiple_modal.completed.description',
            )}
      </Text>
      <CardWrapper>
        <DescriptionSection
          bordered={false}
          size="small"
          background="transparent"
          data={[
            {
              label: t(
                'international_transfer.submit_multiple_modal.completed.details',
              ),
              description: '',
            },
            {
              label: t(
                'international_transfer.submit_multiple_modal.completed.transactions_submitted',
              ),

              description: values.result?.completed,
            },
            {
              label: t(
                'international_transfer.submit_multiple_modal.completed.transactions_failed',
              ),
              description: values.result?.failedTransactions?.length && (
                <StyledDescriptionSection
                  bordered={false}
                  size="small"
                  background="transparent"
                  data={values.result.failedTransactions.map((e, i) => ({
                    label: i + 1,
                    description: (
                      <EllipsisTooltip
                        maxTextContainerWidth="400px"
                        title={e.message}
                      >
                        {e.message}
                      </EllipsisTooltip>
                    ),
                  }))}
                />
              ),
            },
          ]}
        />
      </CardWrapper>
    </>
  );
};

const StyledDescriptionSection = styled(DescriptionSection)`
  .ant-descriptions-item-label {
    width: 40px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }
`;

export default BulkCompleted;
