import React from 'react';

// helpers
import useTranslation from 'hooks/useTranslation';
import { ConfirmationMessageValidationSchema } from '../../../validations/additional/modalDialogs';

// components
import InnerForm from './InnerForm';
import Form, { RequiredPropsForFormModel } from '@core_components/Form';
import ModalDialog, {
  RequiredPropsForModalDialogModel,
} from '@core_components/ModalDialog';

export interface FormValuesModel {
  reason: string;
}

interface IProps
  extends RequiredPropsForModalDialogModel,
    RequiredPropsForFormModel<FormValuesModel> {
  title: string;
  reasonFieldLabel?: string;
  submitButtonText: string;
  submitButtonDanger?: boolean;
  cancelButtonText?: string;
}

const ConfirmationWithReason = ({
  title,
  submitButtonText,
  isVisible,
  closeCallback,
  onSubmit,
  submitButtonDanger,
  cancelButtonText,
  reasonFieldLabel,
  initialValues,
}: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Form<FormValuesModel>
      confirmExitWithoutSaving={false}
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={isVisible ? initialValues : null}
      validationSchema={ConfirmationMessageValidationSchema}
      renderForm={
        <ModalDialog
          width={520}
          title={title}
          isVisible={isVisible}
          closeCallback={closeCallback}
          submitButtonProps={{
            text: submitButtonText,
            danger: submitButtonDanger,
          }}
          cancelButtonProps={{
            text: cancelButtonText || t('discard_changes'),
          }}
        >
          <InnerForm reasonFieldLabel={reasonFieldLabel} />
        </ModalDialog>
      }
    />
  );
};

export default ConfirmationWithReason;
