import React from 'react';

// helpers
import { EntityHelpers } from '../../../../../../helpers/crm/entity';
import { ContactHelpers } from '../../../../../../helpers/crm/contact';
import { AutocompleteProps } from '@core_components/Autocomplete';
import { AUTOCOMPLETE_RESULT_LIMIT } from '../../../../../../constants/global';
import {
  OnboardingEntryTypes,
  RelationshipTrackTypes,
} from '../../../../../../enums/onboarding/crm';
import {
  ClientGroupMemberModel,
  userManagementAPINew,
} from '../../../../../../api/accountManagement/userManagementAPINew';

// components
import FormAutocomplete from '@common_components/Form/FormAutocomplete';

export interface ClientGroupMembersAutocompleteProps
  extends AutocompleteProps<ClientGroupMemberModel> {
  entryType: OnboardingEntryTypes;
  isController?: boolean;
  hasOnlineAccess?: boolean;
  relationshipScopeId?: string;
  relationshipTemplateId?: string;
  relationshipTemplateMemberType?: RelationshipTrackTypes;
  isPossibleMemberOfInitialApprovalGroup?: boolean;
}

const ClientGroupMembersAutocomplete = ({
  entryType,
  isController,
  hasOnlineAccess,
  relationshipScopeId,
  relationshipTemplateId,
  relationshipTemplateMemberType,
  isPossibleMemberOfInitialApprovalGroup,
  ...rest
}: ClientGroupMembersAutocompleteProps) => {
  const fetchOptions = async (searchText: string) => {
    const { data } = await userManagementAPINew.fetchClientGroupMembers({
      limit: AUTOCOMPLETE_RESULT_LIMIT,
      page: 1,
      type: entryType,
      search: searchText,
      has_online_access: hasOnlineAccess,
      isController,
      relationshipScopeId,
      relationshipTemplateId,
      relationshipTemplateMemberType,
      isPossibleMemberOfInitialApprovalGroup,
    });

    return data.map((e) => ({
      id: e.id,
      model: e,
      label:
        e.type === OnboardingEntryTypes.Contact
          ? ContactHelpers.getFormattedContactName(
              e.contact.firstName,
              e.contact.lastName,
              e.contact.middleName,
            )
          : EntityHelpers.getEntityNameByNameType(e.organization?.names || []),
    }));
  };

  return <FormAutocomplete {...rest} fetchData={fetchOptions} />;
};

export default ClientGroupMembersAutocomplete;
